import React from "react";
import { Spinner } from "react-bootstrap";

import "./Loading.module.css";

const styles = {
  loaderContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%", // Fill the width of the parent
    height: "100%", // Fill the height of the parent
  },
};

const Loading = () => (
  <div style={styles.loaderContainer}>
    <Spinner animation="border" variant="primary" role="status" />
  </div>
);

export default Loading;
