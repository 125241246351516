import Badge from "react-bootstrap/Badge";

import { useUser } from "../../context/UserContext";

import classes from "./ProfileHeader.module.css";

const ProfileHeader = () => {
  const { userData } = useUser();
  // const userData = useSelector(selectUserData);
  // const userContactDetails = useSelector(selectUserContactDetails);

  const fullName = userData?.name
    ? userData?.name + " " + (userData?.lastname ? userData?.lastname : "")
    : userData?.email;
  const userType = userData?.userType;
  const company = userData?.company;

  return (
    <div className={classes.center}>
      <h2>{fullName}</h2>
      <Badge as="div" bg="info">
        {userType}
      </Badge>
      <span>{company && "@" + company}</span>
    </div>
  );
};

export default ProfileHeader;
