import classes from "./TabHeader.module.css";
import { useTranslation } from "react-i18next";

import Image from "react-bootstrap/Image";
import { PeopleFill, PersonBadgeFill, KeyFill } from "react-bootstrap-icons";
import imgApiHeader from "../../assets/profile-api-header-3.png";
import imgTeamHeader from "../../assets/profile-team-header-2.png";
import imgProfileHeader from "../../assets/profile-header-3.png";

const ProfileHeader = (props) => {
  const { t } = useTranslation();
  const activeTab = props.activeTab;
  let headerImage = imgProfileHeader;
  let headerTitle = t("Manage your personal information");
  let headerInfo = t("profile-tab-info");
  let headerLink = "";
  let tabIcon = <PersonBadgeFill />;

  if (activeTab === "team") {
    headerImage = imgTeamHeader;
    headerTitle = t("Manage your team");
    headerInfo = t("team-tab-info");
    tabIcon = <PeopleFill />;
  }

  if (activeTab === "api-keys") {
    headerImage = imgApiHeader;
    headerTitle = t("Manage your api keys");
    headerInfo = t("api-keys-tab-info")
    headerLink = (
      <a href="https://help.runonrufus.com/public-api" target="_blank" rel="noreferrer">
        {t("Public API documentation")}{" "}
      </a>
    );
    tabIcon = <KeyFill />;
  }

  return (
    <div className={classes.header}>
      <Image rounded src={headerImage} />
      <h3>
        {tabIcon} {headerTitle}
      </h3>
      <h6>{headerInfo} {headerLink}</h6>
    </div>
  );
};

export default ProfileHeader;
