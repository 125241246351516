import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Carousel,
  Image,
  Form,
  Pagination,
  ListGroup,
  Stack,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";

import onboardingStep1 from "../../assets/onboarding-1.png";
import onboardingStep2 from "../../assets/onboarding-2.png";
import onboardingStep3 from "../../assets/onboarding-3.png";

import { DISMISS_NOTIFICATION } from "../../graphql/mutations";

// Import the CSS file (Rename it to WelcomeModal.css)
import "./WelcomeModal.css";

const notificationId = "welcomeModal-1";

const WelcomeModal = (props) => {
  const { showWelcomeModal, userApiToken, setShowWelcomeModal } = props;
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);
  const [dismissNotification] = useMutation(DISMISS_NOTIFICATION, {
    refetchQueries: ["GetUserDismissedNotifications"],
    onCompleted(data) {
      // Handle completion if needed
    },
    onError(error) {
      console.error(error);
    },
  });
  const onboardingSteps = [
    {
      image: onboardingStep1,
      title: t("Step 1: Bind Your Devices to the Cloud"),
      description: t(
        "This is the first step to get you started with cloud timing!"
      ),
      textBlock: (
        <>
          <p>
            {t(
              "Bind your CloudBox, Timing App, or custom device to the Cloud to make them accessible in the Race Manager (RRM) software, enabling cloud timing, real-time race data access, and localization!"
            )}
          </p>
          <ListGroup variant="flush" className="w-100 custom-list-group">
            <ListGroup.Item>
              <a
                href="https://help.runonrufus.com/rufus-cloud/device-management/binding-timing-devices"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Learn how to bind a timing device")}
              </a>
            </ListGroup.Item>
            <ListGroup.Item>
              <a
                href="https://help.runonrufus.com/rufus-cloud/device-management/devices-list"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t(
                  "See your devices status and location information in real-time"
                )}
              </a>
            </ListGroup.Item>
            <ListGroup.Item>
              <a
                href="https://help.runonrufus.com/public-api"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Learn how to bind any timing device with our Public API")}
              </a>
            </ListGroup.Item>
          </ListGroup>
        </>
      ),
    },
    {
      image: onboardingStep2,
      title: t("Step 2: Create a Race in RUFUS Race Manager software"),
      description: t(
        "All timing devices binded to the Cloud are available in RRM!"
      ),
      textBlock: (
        <>
          <p>
            {t(
              "Create an event, set up checkpoints, races, import participants and get everything ready to start with the race timing using the Cloud!"
            )}
          </p>
          <ListGroup variant="flush" className="w-100 custom-list-group">
            <ListGroup.Item>
              <a
                href="https://help.runonrufus.com/rufus-race-manager/event-management/creating-a-new-event"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Learn how to create an Event")}
              </a>
            </ListGroup.Item>
            <ListGroup.Item>
              {t("Create ")}
              <a
                href="https://help.runonrufus.com/rufus-race-manager/checkpoints/creating-checkpoints"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Checkpoints")}
              </a>
              {t(", ")}
              <a
                href="https://help.runonrufus.com/rufus-race-manager/races/creating-races"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Races")}
              </a>
              {t(" and ")}
              <a
                href="https://help.runonrufus.com/rufus-race-manager/segments/creating-segments"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Segments")}
              </a>
            </ListGroup.Item>
            <ListGroup.Item>
              <a
                href="https://help.runonrufus.com/rufus-race-manager/participant-management/import-participants-from-list"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Import ")}
              </a>
              {t("or ")}
              <a
                href="https://help.runonrufus.com/rufus-race-manager/participant-management/manually-adding-participants"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("manually create Participants")}
              </a>
            </ListGroup.Item>
          </ListGroup>
        </>
      ),
    },
    {
      image: onboardingStep3,
      title: t("Step 3: Link your Devices with the Event and Start Timing!"),
      description: t("Access race data and control devices seamlessly in RRM!"),
      textBlock: (
        <>
          <p>
            {t(
              "Add your cloud devices to the event, assign them to checkpoints, and start cloud timing! Enjoy real-time results and centralized management—all in one place!"
            )}
          </p>
          <ListGroup variant="flush" className="w-100 custom-list-group">
            <ListGroup.Item>
              {t("")}
              <a
                href="https://help.runonrufus.com/rufus-race-manager/timing-devices-integration/devices-menu"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Learn how to link your devices to the event")}
              </a>
            </ListGroup.Item>
            <ListGroup.Item>
              <a
                href="https://help.runonrufus.com/rufus-race-manager/timing-devices-integration/event-devices-view"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("See your devices status and management controls")}
              </a>
            </ListGroup.Item>
            <ListGroup.Item>
              <a
                href="https://help.runonrufus.com/rufus-race-manager/collecting-and-managing-timing-data/timing-the-race"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Start timing!")}
              </a>
            </ListGroup.Item>
          </ListGroup>
        </>
      ),
    },
  ];

  const handleClose = async () => {
    if (doNotShowAgain) {
      try {
        await dismissNotification({
          variables: {
            notification_id: notificationId,
            user_api_token: userApiToken,
          },
        });
      } catch (error) {
        console.error("Error dismissing notification:", error);
      }
    }

    setShowWelcomeModal(false);
  };

  const handleSelect = (selectedIndex) => {
    setCurrentSlide(selectedIndex);
  };

  return (
    <Modal
      show={showWelcomeModal}
      // Remove the onHide prop to prevent closing via the backdrop or ESC key
      centered
      size="lg"
      backdrop="static" // Prevent closing by clicking outside
      keyboard={false} // Prevent closing with keyboard
      aria-labelledby="welcome-modal-title"
    >
      <Modal.Header className="modalHeader">
        {/* Removed closeButton to disable the 'X' button */}
        <Modal.Title id="welcome-modal-title">
          <Stack gap={0}>
            <span className="modalTitle">{t("Welcome to RUFUS Cloud!")} </span>
            <span className="modalSubTitle">
              {t("Follow these simple steps to get you started:")}
            </span>
            {/* <span className="modalSubTitleSub mt-1">
              {t(
                "Discover how to unlock the full potential of RUFUS Cloud in these three simple steps. "
              )}
            </span> */}
          </Stack>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Carousel
          activeIndex={currentSlide}
          onSelect={handleSelect}
          indicators={false}
          controls={false}
          interval={null} // Disable automatic cycling
        >
          {onboardingSteps.map((step, index) => (
            <Carousel.Item key={index}>
              <div className="d-flex flex-column align-items-center text-center">
                <Image
                  src={step.image}
                  alt={`Step ${index + 1}`}
                  fluid
                  style={{ maxHeight: "200px" }}
                />
                <h5 className="mt-3">{t(step.title)}</h5>
                <p>{t(step.description)}</p>
                <div className="mt-2">{step.textBlock}</div>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </Modal.Body>
      <Modal.Footer className="justify-content-between modalFooter">
        {/* "Do not show again" checkbox */}
        <Form.Check
          type="checkbox"
          label={t("Do not show again")}
          className="dismissCheck"
          checked={doNotShowAgain}
          onChange={(e) => setDoNotShowAgain(e.target.checked)}
        />
        <div className="d-flex align-items-center">
          {/* Pagination Controls */}
          <Pagination className="mb-0 me-2">
            <Pagination.Prev
              onClick={() => setCurrentSlide((prev) => Math.max(prev - 1, 0))}
              disabled={currentSlide === 0}
            />
            <Pagination.Next
              onClick={() =>
                setCurrentSlide((prev) =>
                  Math.min(prev + 1, onboardingSteps.length - 1)
                )
              }
              disabled={currentSlide === onboardingSteps.length - 1}
            />
          </Pagination>
          {/* Close Button */}
          <Button variant="primary" onClick={handleClose}>
            {t("Close")}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default WelcomeModal;
