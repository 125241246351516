import { Fragment, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import * as yup from "yup";
import { useMutation } from "@apollo/client";

import { CREATE_INVITATION } from "../../graphql/mutations";

import { useUser } from "../../context/UserContext";

import classes from "./Invitations.module.css";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";

import { ToastError, ToastSuccess } from "../Helpers/ToastHelper";

const schema = yup
  .object()
  .shape({
    collaboratorEmail: yup
      .string()
      .email(i18n.t("Invalid email address"))
      .required(i18n.t("An email is required")) //.typeError('An email is required typeError'),
      .nullable(),
  })
  .required();

const Invitations = () => {
  const { t } = useTranslation();
  const { userData } = useUser();
  const [createInvitation, { loading: isSendingInvitation }] = useMutation(
    CREATE_INVITATION,
    {
      refetchQueries: ["GetUserInvitations"],
      onCompleted(data) {
        if (data.createInvitation?.id) {
          ToastSuccess("InvitationSent");
          reset();
        }
      },
      onError(error) {
        console.error(error);
        ToastError(error);
      },
    }
  );
  const company_api_token = userData?.company_api_token || null;
  const api_token = userData?.api_token || null;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange", //onSubmit
    resolver: yupResolver(schema),
  });

  const submitHandler = (data) => {
    const invitation = {
      invitation_email: data.collaboratorEmail,
      company_api_token,
      inviter_api_token: api_token,
      user_type: data.collaboratorUserType,
    };

    createInvitation({
      variables: {
        invitation,
      },
    });
  };

  const errorHandler = (error) => {
    console.error(error);
  };

  return (
    <Fragment>
      <div className={classes.header}>
        <h4>{t("Send a new invitation")}</h4>
        <h6>{t("send-new-invitation-subtitle")}</h6>
      </div>
      <Form
        name="invitationForm"
        noValidate
        onSubmit={handleSubmit(submitHandler, errorHandler)}
      >
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridCollaboratorEmail">
            <Form.Label>{t("Email")}</Form.Label>
            <Form.Control
              type="email"
              placeholder={t("My collaborator email")}
              {...register("collaboratorEmail")}
            />
            {errors.collaboratorEmail && (
              <Form.Text className="text-danger">
                {errors.collaboratorEmail.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group as={Col} controlId="formGridCollaboratorType">
            <Form.Label>{t("Type")}</Form.Label>
            <Form.Select
              aria-label={t("Collaborator type")}
              {...register("collaboratorUserType")}
            >
              <option value="READ_ONLY">Read only</option>
              <option value="TIMER">Timer</option>
              <option value="ADMIN">Admin</option>
            </Form.Select>
          </Form.Group>
        </Row>
        <div className={classes.button_container}>
          <Button
            variant="primary"
            type="submit"
            disabled={isSendingInvitation}
          >
            {isSendingInvitation ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />{" "}
                {t("Sending...")}
              </>
            ) : (
              t("Send invitation")
            )}
          </Button>
        </div>
      </Form>
    </Fragment>
  );
};

export default Invitations;
