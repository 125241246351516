import { useTranslation } from "react-i18next";

import classes from "./AllDevices.module.css";

import Spinner from "react-bootstrap/Spinner";

import DevicesList from "./DevicesList";
import NoDevices from "./NoDevices";
import Loading from "../Loading/Loading";

const AllDevices = (props) => {
  const { t } = useTranslation();
  const { devices, loadingDevices } = props;

  return (
    <>
      <div className={classes.header}>
        <h1>{t("Devices list")}</h1>
        {devices && devices.length > 0 && <h6>{t("devices-subtitle")}</h6>}
        {devices && devices.length === 0 && (
          <h6>
            {t(
              "Bind your first device to get you started! Learn more about compatible devices in "
            )}
            <a
              className={classes.linkButton}
              href="https://help.runonrufus.com/rufus-cloud/device-management/binding-timing-devices"
              target="_blank"
              rel="noreferrer"
            >
              {t("our documentation")}
            </a>
            .
          </h6>
        )}
      </div>
      {loadingDevices && <Loading />}
      {!loadingDevices && devices && devices.length > 0 && (
        <>
          <DevicesList devices={devices} />
        </>
      )}
      {devices && devices.length === 0 && <NoDevices />}
    </>
  );
};

export default AllDevices;
