import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { formatDistanceToNow } from "date-fns";

import { GENERATE_TOKEN_DEVICE } from "../../graphql/mutations";

import { useUser } from "../../context/UserContext";

import { USERTYPES } from "../../store/models/user-types";

import classes from "./GenerateTokenCard.module.css";

import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";

import {
  ToastError,
  ToastSuccess,
  ToastInfo,
  ToastSuccessDismiss,
  ToastErrorDismiss,
  ToastInfoDismiss,
} from "../Helpers/ToastHelper";

const isDateInPast = (dateTime) => {
  const inputDate = new Date(dateTime);
  const now = new Date();

  if (isNaN(inputDate.getTime())) {
    return true;
  }

  return inputDate < now;
};

const GenerateTokenCard = () => {
  const { t } = useTranslation();
  const { userData } = useUser();
  const user_api_token = userData?.api_token || null;
  const userType = userData?.user_type || "READ_ONLY";
  const token_device = userData?.token_device || null;
  const token_until = userData?.token_until || null;
  const isTokenDue = isDateInPast(token_until);
  const [token, setToken] = useState({ token_device, token_until });
  const [generateToken, { loading }] = useMutation(GENERATE_TOKEN_DEVICE, {
    refetchQueries: [
      "GetUserByCredentials", // Query name
    ],
    onCompleted(data) {
      if (data.generateTokenDevice) {
        const { token_device, token_until } = data.generateTokenDevice;
        setToken({
          token_device,
          token_until,
        });
        ToastSuccess("NewTokenGenerated", { token: token_device });
      }
    },
    onError(error) {
      console.error(error);
      ToastError("DeviceTokenException");
    },
  });

  const generateTokenHandler = () => {
    // ToastSuccess("ProfileUpdated");
    // ToastError("LimitExceededException");
    // ToastInfo("newDeviceBinded");
    // ToastInfo("deviceUnbinded");
    // ToastInfo("newSessionInserted");
    // ToastSuccessDismiss("CloudBox CLBX10001 is now online");
    // ToastErrorDismiss("CloudBox CLBX10001 went offline");
    // ToastInfoDismiss('cloudBoxOnline', { serialNumber:'CLBX10001' });

    if (userType && userType !== USERTYPES.READ_ONLY) {
      generateToken({
        variables: {
          api_token: user_api_token,
        },
      });
    }
  };

  return (
    <Fragment>
      <Col>
        <Card className={classes.card}>
          <Card.Body>
            <Card.Title>{t("Bind a new device")}</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              {t("bind-device-subtitle")}
            </Card.Subtitle>
            <Card.Text>
              <Button
                variant="primary"
                onClick={generateTokenHandler}
                disabled={loading || userType === USERTYPES.READ_ONLY}
                className={classes.button}
              >
                {loading ? (
                  <>
                    <Spinner
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    {t("Generating...")}
                  </>
                ) : (
                  t("Generate token")
                )}
              </Button>
              <br></br>
              <span>{isTokenDue ? "" : token?.token_device} </span>
            </Card.Text>

            {token?.token_until && !isTokenDue && (
              <Card.Footer className="text-center">
                <small className="text-muted">
                  {t("Expires")}{" "}
                  {formatDistanceToNow(new Date(token?.token_until), {
                    addSuffix: true,
                  })}
                </small>
              </Card.Footer>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Fragment>
  );
};

export default GenerateTokenCard;
