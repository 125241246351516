import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, NetworkStatus } from "@apollo/client";

import {
  GET_DEVICES_BY_COMPANY_WITH_STATUS,
  GET_DEVICE_SESSIONS,
  GET_SESSION_PASSINGS,
} from "../graphql/queries";

import { useUser } from "../context/UserContext";
import { useNotification } from "../context/NotificationContext";

import AllDevices from "../components/Devices/AllDevices";
import DeviceDetails from "../components/Devices/DeviceDetails";
import AllPassings from "../components/Passings/AllPassings";
import Bread from "../components/UI/Bread";
import HelpComponent from "../components/UI/HelpComponent";

import "./DevicesPage.css";

const DevicesPage = () => {
  const params = useParams();
  const { userData } = useUser();
  const { devicesCloudBox } = useNotification();
  const { deviceId, sessionId } = params;
  const company_api_token = userData?.company_api_token || null;
  const [currentDevice, setCurrentDevice] = useState({});
  const [currentSession, setCurrentSession] = useState({});
  const [devices, setDevices] = useState({});
  const [cloudBoxSerialNumbers, setCloudBoxSerialNumbers] = useState(new Set());
  const [sessions, setSessions] = useState({});
  const [passings, setPassings] = useState({});
  const { data: devicesData, networkStatus: nsDevices } = useQuery(
    GET_DEVICES_BY_COMPANY_WITH_STATUS,
    {
      variables: { company_api_token },
      skip: !company_api_token,
      fetchPolicy: "cache-and-network",
      returnPartialData: false,
      partialRefetch: true,
      pollInterval: 10000,
      notifyOnNetworkStatusChange: true,
    }
  );
  const {
    data: sessionsData,
    networkStatus: nsSessions,
    startPolling: startPollingSession,
    stopPolling: stopPollingSession,
  } = useQuery(GET_DEVICE_SESSIONS, {
    variables: { company_api_token, device_id: currentDevice?.id },
    skip: !company_api_token || !currentDevice?.id,
    fetchPolicy: "cache-and-network",
    returnPartialData: false,
    partialRefetch: true,
    notifyOnNetworkStatusChange: true,
  });
  const {
    data: passingsData,
    networkStatus: nsPassings,
    startPolling: startPollingPassings,
    stopPolling: stopPollingPassings,
  } = useQuery(GET_SESSION_PASSINGS, {
    variables: { token_session: currentSession?.token_session },
    skip: !company_api_token || !currentSession?.token_session,
    fetchPolicy: "cache-and-network",
    returnPartialData: false,
    partialRefetch: true,
    notifyOnNetworkStatusChange: true,
  });
  const isInitialLoadingPassings =
    (nsPassings === NetworkStatus.loading ||
      nsPassings === NetworkStatus.setVariables) &&
    !passingsData;
  const isInitialLoadingSessions =
    (nsSessions === NetworkStatus.loading ||
      nsSessions === NetworkStatus.setVariables) &&
    !sessionsData;
  const isInitialLoadingDevices =
    (nsDevices === NetworkStatus.loading ||
      nsDevices === NetworkStatus.setVariables) &&
    !devicesData;

  // Helper function to merge status data
  const mergeStatus = (deviceStatus, statusUpdate, connectionUpdate) => {
    // Start with the existing status or an empty object
    const newStatus = { ...deviceStatus };

    // Update from statusUpdate
    if (statusUpdate) {
      const gpsInfo = statusUpdate.gpsInfo || {};
      const status = statusUpdate.status || {};

      newStatus.location = {
        statusGps: status?.statusGps?.status || null,
        statusGpsMessage: status?.statusGps?.message || null,
        lastSync: gpsInfo.lastSync || newStatus.location?.lastSync || null,
        latitudeDecimal:
          gpsInfo.latitudeDecimal?.toString() ||
          newStatus.location?.latitudeDecimal ||
          null,
        longitudeDecimal:
          gpsInfo.longitudeDecimal?.toString() ||
          newStatus.location?.longitudeDecimal ||
          null,
        googleMapsLink:
          gpsInfo.googleMapsLink || newStatus.location?.googleMapsLink || null,
      };

      newStatus.lastPassingTimestamp =
        status.lastPassingTimestamp || newStatus.lastPassingTimestamp || null;
      newStatus.batteryVolts =
        status.batteryVolts || newStatus.batteryVolts || null;
      newStatus.batteryPercentage =
        status.batteryPercentage || newStatus.batteryPercentage || null;
      newStatus.cpuTemperature =
        status.cpuTemperature || newStatus.cpuTemperature || null;
      newStatus.hasPower = status.hasPower ?? newStatus.hasPower ?? null;
      newStatus.startMode = status.startMode ?? newStatus.startMode ?? null;

      newStatus.releaseDate =
        statusUpdate.releaseDate || newStatus.releaseDate || null;
      newStatus.timestamp =
        statusUpdate.timestamp || newStatus.timestamp || null;
    }

    // Update from connectionUpdate
    if (connectionUpdate) {
      newStatus.connected =
        connectionUpdate.connected ?? newStatus.connected ?? null;
      newStatus.disconnectReason =
        connectionUpdate.disconnectReason || newStatus.disconnectReason || null;
      newStatus.timestamp =
        connectionUpdate.timestamp || newStatus.timestamp || null;
    }

    return newStatus;
  };

  useEffect(() => {
    if (sessionId && sessions && sessions.length) {
      const session = sessions.find((s) => s._id === sessionId);

      if (session.active) {
        startPollingPassings(5000);
      } else {
        stopPollingPassings();
      }

      setCurrentSession(session);
    } else {
      setCurrentSession({});
    }
  }, [sessions, sessionId, startPollingPassings, stopPollingPassings]);

  useEffect(() => {
    if (deviceId && devices && devices.length > 0) {
      const device = devices.find((d) => d.id === deviceId);

      setCurrentDevice(device);
    } else {
      setCurrentDevice({});
    }
  }, [devices, deviceId]);

  useEffect(() => {
    if (devicesData && devicesData.getDevicesByCompanyWithStatus) {
      // const devicesList = devicesData.getDevicesByCompanyWithStatus;
      const devicesList = devicesData.getDevicesByCompanyWithStatus.map(
        (device) => ({
          ...device,
          status: device.status || {}, // Ensure status is an object
        })
      );

      // console.log("devicesList:", devicesList);

      // Update devices state
      setDevices(devicesList);

      // Build a set of serial numbers for CloudBox devices
      const cloudBoxSerials = new Set(
        devicesList
          .filter(
            (device) => device.model && device.model.startsWith("CloudBox")
          )
          .map((device) => device.serial_number)
      );

      setCloudBoxSerialNumbers(cloudBoxSerials);
    }
  }, [devicesData]);

  useEffect(() => {
    if (sessionsData && sessionsData.getDeviceSessions) {
      setSessions(sessionsData.getDeviceSessions);

      const hasActiveSession = sessionsData.getDeviceSessions.some(
        (session) => session.active === true
      );
      if (hasActiveSession) {
        startPollingSession(5000);
      } else {
        stopPollingSession();
      }
    } else {
      stopPollingSession();
    }
  }, [sessionsData, startPollingSession, stopPollingSession]);

  useEffect(() => {
    if (passingsData && passingsData.getSessionPassings) {
      setPassings(passingsData.getSessionPassings);
    }
  }, [passingsData]);

  // Update devices state with real-time notifications
  useEffect(() => {
    if (devicesCloudBox && cloudBoxSerialNumbers.size > 0) {
      setDevices((prevDevices) => {
        if (!prevDevices) return prevDevices;

        const updatedDevices = prevDevices.map((device) => {
          const serialNumber = device.serial_number;

          // Only process CloudBox devices
          if (cloudBoxSerialNumbers.has(serialNumber)) {
            const update = devicesCloudBox[serialNumber];

            if (update) {
              const newStatus = mergeStatus(
                device.status,
                update.statusUpdate,
                update.connectionUpdate
              );

              return {
                ...device,
                status: newStatus,
              };
            } else {
              return device;
            }
          } else {
            // For non-CloudBox devices, return the device as is
            return device;
          }
        });

        // console.log("updatedDevices:", updatedDevices);

        return updatedDevices;
      });
    }
  }, [devicesCloudBox, cloudBoxSerialNumbers]);

  return (
    <>
      {!deviceId && !sessionId ? (
        <>
          {/* <div className="help-component-container">
            <HelpComponent helpTopic="example" />
          </div> */}
          <AllDevices
            devices={devices}
            loadingDevices={isInitialLoadingDevices}
          />
        </>
      ) : deviceId && !sessionId ? (
        <>
          <Bread
            currentDevice={currentDevice}
            currentSession={currentSession}
          ></Bread>
          {/* <div className="help-component-container">
            <HelpComponent helpTopic="example" />
          </div> */}
          <DeviceDetails
            currentDevice={currentDevice}
            sessions={sessions}
            loadingSessions={isInitialLoadingSessions}
          ></DeviceDetails>
        </>
      ) : deviceId && sessionId ? (
        <>
          <Bread
            currentDevice={currentDevice}
            currentSession={currentSession}
          ></Bread>
          <AllPassings
            currentDevice={currentDevice}
            currentSession={currentSession}
            passings={passings}
            loadingPassings={isInitialLoadingPassings}
          ></AllPassings>
        </>
      ) : null}
    </>
  );
};

export default DevicesPage;
