import { Fragment } from "react";

import Container from "react-bootstrap/Container";

import Navbar from "./Navbar";

const Layout = (props) => {
  const { showNotificationIcon, onNotificationIconClick } = props;
  return (
    <Fragment>
      <Navbar
        showNotificationIcon={showNotificationIcon}
        onNotificationIconClick={onNotificationIconClick}
      />
      <Container>
        <main>{props.children}</main>
      </Container>
    </Fragment>
  );
};

export default Layout;
