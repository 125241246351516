import { useState, useRef, useEffect } from "react";
import { PencilFill } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";

import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Badge from "react-bootstrap/Badge";

import { CHANGE_DEVICE_ALIAS } from "../../graphql/mutations";

import { useUser } from "../../context/UserContext";
import { USERTYPES } from "../../store/models/user-types";

import classes from "./DeviceDetails.module.css";

import DeviceStatusDetails from "./DeviceStatusDetails";
import DeviceLocation from "./DeviceLocation";
import SessionsList from "../Sessions/SessionsList";
import NoSessions from "../Sessions/NoSessions";
import Loading from "../Loading/Loading";
import { ToastError, ToastSuccess } from "../Helpers/ToastHelper";

import { Icon } from "../UI/Icon";

const DeviceDetails = (props) => {
  const { t } = useTranslation();
  const refDeviceAlias = useRef();
  const aliasContainerRef = useRef(null);
  const { userData } = useUser();
  const { currentDevice, sessions, loadingSessions } = props;
  const company_api_token = userData?.company_api_token || null;
  const userType = userData?.user_type || "READ_ONLY";
  const [showEdit, setShowEdit] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [changeDeviceAlias] = useMutation(CHANGE_DEVICE_ALIAS, {
    refetchQueries: ["GetDevicesByCompanyWithStatus"],
    onCompleted(data) {
      if (data.changeDeviceAlias === "SUCCESS") {
        ToastSuccess("DeviceAliasUpdated");
      }
    },
    onError(error) {
      console.error(error);
      ToastError("DeviceAliasException");
    },
  });

  // Extract device main properties
  const id = currentDevice?.id;
  const alias = currentDevice?.alias;
  const eventName = currentDevice?.event_name;
  const isCloudBox = currentDevice?.isCloudBox;
  const numOfSessions = currentDevice?.numOfSessions;
  const locationFromPassing = {
    latitudeDecimal: currentDevice?.last_passing_known_latitude,
    longitudeDecimal: currentDevice?.last_passing_known_longitude,
    lastSync: currentDevice?.last_passing_location_timestamp?.endsWith("Z")
      ? currentDevice?.last_passing_location_timestamp?.slice(0, -1)
      : currentDevice?.last_passing_location_timestamp,
    locationFromPassing: true,
  };

  // Extract device status properties
  const status = currentDevice?.status;
  const isConnected = status?.connected;
  const startMode = status?.startMode;
  const disconnectReason = status?.disconnectReason;
  const hasPower = status?.hasPower;
  const batteryPercentage = parseFloat(status?.batteryPercentage) || 0;
  const batteryVolts = status?.batteryVolts;
  const cpuTemperature = status?.cpuTemperature;
  const location = status?.location ? status?.location : locationFromPassing;

  const getConnectionIcon = () => {
    if (!isCloudBox) return null;

    // Determine the appropriate battery icon based on battery percentage and charging status
    let iconName;
    let color = "grey"; // Default icon color
    let tooltipText = null;

    if (isConnected && startMode) {
      iconName = "Wifi";
      color = "blue";
      tooltipText = `Online and reading`;
    }
    if (isConnected && !startMode) {
      iconName = "Wifi";
      color = "green";
      tooltipText = `Online`;
    }
    if (!isConnected) {
      iconName = "WifiOff";
      color = "grey";
      tooltipText = `Offline ${disconnectReason}`;
    }

    // Tooltip content
    const tooltip = (
      <Tooltip id={`tooltip-connection}`}>{`${tooltipText}`}</Tooltip>
    );

    return (
      <OverlayTrigger placement="top" overlay={tooltip}>
        <span className={`d-flex align-items-center ${classes.pointer}`}>
          <Icon iconName={iconName} color={color} size="30" />
        </span>
      </OverlayTrigger>
    );
  };

  const getBatteryIcon = () => {
    if (!isCloudBox) return null;

    // Ensure batteryPercentage and batteryVolts are valid numbers
    const batteryPercentRaw = parseFloat(batteryPercentage);
    const batteryVolt = parseFloat(batteryVolts);

    if (isNaN(batteryPercentRaw) || isNaN(batteryVolt)) {
      return null; // Return null or a placeholder if data is invalid
    }

    // Round the battery percentage to the nearest whole number
    const batteryPercent = Math.round(batteryPercentRaw);

    // Determine the appropriate battery icon based on battery percentage and charging status
    let iconName;
    let color = "grey"; // Default icon color
    let charging = null;

    if (hasPower) {
      iconName = "BatteryCharging";
      color = "green";
      charging = "CHARGING";
    } else if (batteryPercent >= 75) {
      iconName = "BatteryFull";
      color = "green";
    } else if (batteryPercent >= 50) {
      iconName = "BatteryHalf";
      color = "green";
    } else if (batteryPercent >= 25) {
      iconName = "Battery";
      color = "orange";
    } else {
      iconName = "Battery";
      color = "red";
    }

    if (!isConnected) color = "grey";

    const iconSize = 25; // Adjust the icon size as needed

    // Style for the battery percentage text
    const textStyle = {
      fontSize: `${iconSize * 0.5}px`, // 50% of the icon size
      marginLeft: "5px",
      verticalAlign: "middle",
      color: "grey",
    };

    // Tooltip content
    const tooltip = charging ? (
      <Tooltip id={`tooltip-battery-${iconName}`}>
        {`${charging}`} {<br />} {`${batteryVolt}V`}
      </Tooltip>
    ) : (
      <Tooltip id={`tooltip-battery-${iconName}`}>{`${batteryVolt}V`}</Tooltip>
    );

    return (
      <OverlayTrigger placement="top" overlay={tooltip}>
        <span className={`d-flex align-items-center ${classes.pointer}`}>
          <Icon iconName={iconName} color={color} size={iconSize} />
          <span style={textStyle}>{`${batteryPercent}%`}</span>
        </span>
      </OverlayTrigger>
    );
  };

  const getTemperatureIcon = () => {
    if (!isCloudBox) return null;

    if (cpuTemperature == null || isNaN(cpuTemperature)) {
      return null;
    }

    // Round the CPU temperature to the nearest whole number
    const temp = Math.round(cpuTemperature);

    // Determine the appropriate thermometer icon based on CPU temperature
    let iconName;
    let color = "grey"; // Default icon color

    if (temp >= 75) {
      iconName = "ThermometerHigh";
      color = "red";
    } else if (temp >= 65) {
      iconName = "ThermometerHalf";
      color = "orange";
    } else {
      iconName = "ThermometerLow";
      color = "blue";
    }

    if (!isConnected) color = "grey";

    const iconSize = 25; // Adjust the icon size as needed

    // Style for the temperature text
    const textStyle = {
      fontSize: `${iconSize * 0.5}px`, // 50% of the icon size
      verticalAlign: "middle",
      color: "grey",
    };

    // Tooltip content
    const tooltip = (
      <Tooltip id={`tooltip-cputemp`}>{`CPU Temperature`}</Tooltip>
    );

    return (
      <OverlayTrigger placement="top" overlay={tooltip}>
        <span className={`d-flex align-items-center ${classes.pointer}`}>
          <Icon iconName={iconName} color={color} size={iconSize} />
          <span style={textStyle}>{`${temp}°C`}</span>
        </span>
      </OverlayTrigger>
    );
  };

  const getDeviceEvent = () => {
    if (eventName) {
      return (
        <span className="d-flex align-items-center mt-2">
          <Badge bg="danger">
            <Icon
              iconName={"CalendarEvent"}
              size={"13"}
              className="align-top"
            />
            {` ${eventName}`}
          </Badge>
        </span>
      );
    }
    return (
      <span className="d-flex align-items-center mt-2">
        <Badge bg="success">{t(`Available`)}</Badge>
      </span>
    );
  };

  const enterMouseHandler = () => {
    if (userType !== USERTYPES.READ_ONLY) setShowEdit(true);
  };

  const leaveMouseHandler = () => {
    if (!isEditing) setShowEdit(false);
  };

  const handleFocus = () => {
    if (userType !== USERTYPES.READ_ONLY) setShowEdit(true);
  };

  const handleBlur = () => {
    if (!isEditing) setShowEdit(false);
  };

  const saveDeviceAlias = () => {
    if (isEditing) {
      const new_alias = refDeviceAlias.current.value.trim();
      const device_id = id;

      if (new_alias && new_alias !== alias) {
        changeDeviceAlias({
          variables: {
            company_api_token,
            device_id,
            new_alias,
          },
        });
      }
    }
    setIsEditing(!isEditing);

    if (isEditing) {
      setShowEdit(false);
    } else {
      setShowEdit(true);
    }
  };

  const clickHandler = () => {
    saveDeviceAlias();
  };

  const keyPressHandler = (event) => {
    if (event.key === "Enter") {
      saveDeviceAlias();
    }
  };

  const handleClickOutside = (event) => {
    if (
      isEditing &&
      aliasContainerRef.current &&
      !aliasContainerRef.current.contains(event.target)
    ) {
      saveDeviceAlias();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isEditing]);

  return (
    <>
      {/* Device Status Icons */}
      {isCloudBox && (
        <div className="d-flex align-items-center mb-1">
          {getBatteryIcon()}
          {getTemperatureIcon()}
        </div>
      )}

      {/* Device Alias and Connection Icon */}
      <div
        onMouseEnter={enterMouseHandler}
        onMouseLeave={leaveMouseHandler}
        className={classes.edit}
        ref={aliasContainerRef}
      >
        <div
          className={`d-flex align-items-center flex-nowrap w-100 ${classes.alias}`}
        >
          {/* Connection Icon */}
          {isCloudBox && <span className="me-2">{getConnectionIcon()}</span>}

          {/* Alias and Edit Input */}
          {!isEditing ? (
            <h1
              className="mb-0 flex-shrink-1 text-truncate"
              style={{ minWidth: 0 }}
              tabIndex="0"
              onFocus={handleFocus}
            >
              {alias}
            </h1>
          ) : (
            <Form.Control
              ref={refDeviceAlias}
              type="text"
              defaultValue={alias}
              autoFocus
              onKeyDown={keyPressHandler}
              maxLength={50}
              className={`me-2 flex-shrink-1`}
              placeholder={t("Enter device name")}
              onBlur={handleBlur}
            />
          )}

          {/* Edit Icon */}
          {showEdit && (
            <PencilFill
              size={28}
              onClick={clickHandler}
              className={classes.pencil}
              style={{ cursor: "pointer" }}
              aria-label={isEditing ? "Save Alias" : "Edit Alias"}
            />
          )}
        </div>
      </div>

      {/* Device Availability Icons */}
      <div className="d-flex align-items-center">{getDeviceEvent()}</div>

      <Tabs defaultActiveKey="sessions" id="device-tabs" className="mb-3 mt-3">
        <Tab eventKey="sessions" title={t("Sessions")}>
          {/* Sessions content */}
          {loadingSessions && <Loading />}

          {!loadingSessions && sessions && sessions.length === 0 && (
            <>
              {" "}
              <span>{t("empty-device-info")}</span>
              <NoSessions />
            </>
          )}

          {!loadingSessions && sessions && sessions.length > 0 && (
            <>
              {" "}
              <span>
                {t("There are")} {numOfSessions}{" "}
                {t("available timing sessions in this device.")}
              </span>
              <SessionsList sessions={sessions} />
            </>
          )}
        </Tab>
        <Tab eventKey="location" title={t("Location")}>
          {/* Location information content */}
          <DeviceLocation location={location} />
        </Tab>
        <Tab
          eventKey="status"
          title={t("Status")}
          tabClassName={!isCloudBox ? "d-none" : ""}
        >
          {/* Device status details */}
          <DeviceStatusDetails status={status} />
        </Tab>
      </Tabs>
    </>
  );
};

export default DeviceDetails;
