import { useState } from "react";

import { useMutation } from "@apollo/client";

import { REMOVE_API_KEY } from "../../graphql/mutations";

import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import { Trash3Fill } from "react-bootstrap-icons";

import { useUser } from "../../context/UserContext";

import classes from "./ApiKeyRow.module.css";

import { USERTYPES } from "../../store/models/user-types";

import { ToastError, ToastSuccess } from "../Helpers/ToastHelper";

const ApiKeyRow = (props) => {
  const [hoover, setHoover] = useState(null);
  const { userData } = useUser();
  const company_api_token = userData?.company_api_token || null;
  const userType = userData?.user_type || "READ_ONLY";
  const [removeApiKey] = useMutation(REMOVE_API_KEY, {
    refetchQueries: ["GetUserApiKeys"],
    onCompleted(data) {
      if (data.removeApiKey === "SUCCESS") {
        ToastSuccess("ApiKeyDeleted");
      }
    },
    onError(error) {
      console.error(error);
      ToastError(error);
    },
  });
  const apiKey = props.apiKey;

  const onMouseEnterHandler = (button) => {
    setHoover(button);
  };

  const onMouseLeaveHandler = (button) => {
    setHoover(null);
  };

  return (
    <tr
      onMouseEnter={() => onMouseEnterHandler(apiKey.api_key)}
      onMouseLeave={() => onMouseLeaveHandler(apiKey.api_key)}
      className={classes["table-row"]}
    >
      <td>{apiKey.alias}</td>
      <td>
        <b>{apiKey.api_key}</b>
      </td>
      <td>
        <Badge bg="info">{apiKey.access_type}</Badge>
      </td>
      <td>{apiKey.allow_unbind.toString()}</td>
      <td className={classes["image-col"]}>
        {(userType === USERTYPES.ADMIN || userType === USERTYPES.OWNER) && (
          <Trash3Fill
            onClick={() =>
              removeApiKey({
                variables: {
                  company_api_token,
                  api_key: apiKey.api_key,
                },
              })
            }
            display={hoover === apiKey.api_key ? "inline" : "none"}
          />
        )}
      </td>
    </tr>
  );
};

export default ApiKeyRow;
