/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Toaster } from "react-hot-toast";

import { useUser } from "./context/UserContext";

import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import PublicRoute from "./components/PublicRoute/PublicRoute";
import Layout from "./components/Layout/Layout";
import AuthPage from "./pages/AuthPage";
import DevicesPage from "./pages/DevicesPage"; //Protected
// import EventsPage from "./pages/EventsPage"; //Protected
import ProfilePage from "./pages/ProfilePage"; //Protected
import WelcomeModal from "./components/WelcomeModal/WelcomeModal";

const App = () => {
  const { i18n } = useTranslation();
  const { userData, userNotifications } = useUser();
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [showNotificationIcon, setShowNotificationIcon] = useState(false);

  useEffect(() => {
    if (userData) {
      const { preferred_language } = userData;

      i18n.changeLanguage(preferred_language);
    }
  }, [userData]);

  useEffect(() => {
    if (!userData) return;

    if (userNotifications.includes("welcomeModal-1") === false) {
      setShowWelcomeModal(true);
      setShowNotificationIcon(true);
    } else {
      setShowNotificationIcon(false);
    }
  }, [userNotifications]);

  return (
    <Fragment>
      <Toaster position="top-right" reverseOrder={false} />

      <PrivateRoute>
        <Layout
          showNotificationIcon={showNotificationIcon}
          onNotificationIconClick={setShowWelcomeModal}
        >
          <WelcomeModal
            showWelcomeModal={showWelcomeModal}
            userApiToken={userData?.api_token}
            setShowWelcomeModal={setShowWelcomeModal}
          />
          <Routes>
            <Route path="*" element={<Navigate replace to="/devices" />} />
            <Route path="/devices" element={<DevicesPage />} />
            <Route path="/devices/:deviceId" element={<DevicesPage />} />
            <Route
              path="/devices/:deviceId/:sessionId"
              element={<DevicesPage />}
            />
            <Route path="/profile" element={<ProfilePage />} />
            {/*<Route path="/events" element={<EventsPage />} />
             <Route path="/event/:eventId" element={<EventPage />} />
            */}
          </Routes>
        </Layout>
      </PrivateRoute>

      <PublicRoute>
        <Routes>
          <Route path="*" element={<Navigate replace to="/signin" />} />
          <Route path="/signin" element={<AuthPage />} />
          <Route path="/signup" element={<AuthPage />} />
          <Route path="/signup/:invitation" element={<AuthPage />} />
          <Route path="/forgot" element={<AuthPage />} />
          <Route path="/reset" element={<AuthPage />} />
          <Route path="/change" element={<AuthPage />} />
          <Route path="/confirm" element={<AuthPage />} />
        </Routes>
      </PublicRoute>
    </Fragment>
  );
};

export default App;
