const PassingRow = ({ passing }) => (
  <tr>
    <td>{passing.num_passing}</td>
    <td className="d-none d-sm-table-cell">{passing.bib}</td>
    <td>{passing.chip}</td>
    <td className="d-none d-md-table-cell">{passing.participant_id}</td>
    <td>{passing.timestamp}</td>
    <td>{`${passing.latitude}-${passing.longitude}`}</td>
    <td>{passing.status}</td>
  </tr>
);

export default PassingRow;

// const PassingRow = (props) => {
//   const passing = props.passing;

//   return (
//     <tr>
//       <td>{passing.num_passing}</td>
//       <td>{passing.bib}</td>
//       <td>{passing.chip}</td>
//       <td>{passing.participant_id}</td>
//       <td>{passing.timestamp}</td>
//       <td>
//         {passing.latitude}-{passing.longitude}{" "}
//       </td>
//       <td>{passing.status} </td>
//     </tr>
//   );
// };

// export default PassingRow;
