import { useTranslation } from "react-i18next";

import Button from "react-bootstrap/Button";
import { DoorOpen } from "react-bootstrap-icons";

import classes from "./Logout.module.css";

const Logout = (props) => {
  const handleSignOut = props.handleSignOut;
  const { t } = useTranslation();

  return (
    <div className={classes.right}>
      <Button variant="link" onClick={handleSignOut}>
        {t("Sign out")} <DoorOpen />
      </Button>
    </div>
  );
};

export default Logout;
