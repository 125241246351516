/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";

import { useUser } from "./UserContext";

import {
  ToastError,
  ToastSuccess,
  ToastInfo,
  ToastSuccessDismiss,
  ToastErrorDismiss,
  ToastInfoDismiss,
} from "../components/Helpers/ToastHelper";

// Create the Notification Context
const NotificationContext = createContext();

// Create the Provider Component
export const NotificationProvider = ({ children }) => {
  const { t } = useTranslation();
  const { userData } = useUser();
  const client = useApolloClient(); // Access the Apollo Client instance
  const api_token = userData?.api_token;
  const [reconnectWS, setReconnectWS] = useState(false);
  const [devicesCloudBox, setDevicesCloudBox] = useState({});

  useEffect(() => {
    if (api_token) {
      const ws = new WebSocket(
        `wss://j87wil4iv7.execute-api.us-east-1.amazonaws.com/dev?api_token=${api_token}`
      );

      ws.onopen = () => {
        console.log("Connection established with WebSocket server");
      };

      ws.onmessage = async (e) => {
        let data;
        try {
          data = JSON.parse(e.data);
        } catch (err) {
          console.error("Error parsing WebSocket message:", err);
          return;
        }
        // console.log("WebSocket message received:", data);

        // Handle different notification types
        switch (data.notification) {
          case "new_device_binded":
            ToastInfo("newDeviceBinded");
            await client.refetchQueries({
              include: ["GetDevicesByCompanyWithStatus"],
            });
            break;

          case "device_unbinded":
            ToastInfo("deviceUnbinded");
            await client.refetchQueries({
              include: ["GetDevicesByCompanyWithStatus"],
            });
            break;

          case "new_session_inserted":
            ToastInfo("newSessionInserted");
            await client.refetchQueries({
              include: ["GetDevicesByCompanyWithStatus", "GetDeviceSessions"],
            });
            break;

          case "iot_device_event":
            handleIoTDeviceEvent(data);
            break;

          case "iot_device_status":
            handleIoTDeviceStatus(data);
            break;

          default:
            console.log("Unknown notification type:", data.notification);
        }
      };

      ws.onerror = (e) => {
        console.error("WebSocket error:", e);
        // Optionally, set error state or handle errors here
      };

      ws.onclose = (e) => {
        console.log("WebSocket connection closed:", e);
        // Toggle reconnectWS to trigger reconnection
        setReconnectWS((prev) => !prev);
      };

      // Cleanup on unmount
      return () => {
        ws.close();
      };
    }
  }, [api_token, reconnectWS]);

  // Handler for 'iot_device_event'
  const handleIoTDeviceEvent = (data) => {
    const { connectionUpdate } = data;

    if (connectionUpdate) {
      const { connected, serialNumber } = connectionUpdate;

      if (connected === true) {
        ToastInfoDismiss('cloudBoxOnline', { serialNumber });
      } else if (connected === false) {
        ToastInfoDismiss('cloudBoxOffline', { serialNumber });
      }

      // Update devicesCloudBox state
      setDevicesCloudBox((prevState) => ({
        ...prevState,
        [serialNumber]: {
          ...prevState[serialNumber],
          connectionUpdate,
        },
      }));
    }
  };

  // Handler for 'iot_device_status'
  const handleIoTDeviceStatus = (data) => {
    const { statusUpdate } = data;

    if (statusUpdate) {
      const { serialNumber } = statusUpdate;

      // Update devicesCloudBox state
      setDevicesCloudBox((prevState) => ({
        ...prevState,
        [serialNumber]: {
          ...prevState[serialNumber],
          statusUpdate,
        },
      }));
    }
  };

  return (
    <NotificationContext.Provider value={{ devicesCloudBox }}>
      {children}
    </NotificationContext.Provider>
  );
};

// Custom hook to use the Notification Context
export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotification must be used within a NotificationProvider"
    );
  }
  return context;
};
