import { useTranslation } from 'react-i18next';
import Card from 'react-bootstrap/Card';

const PassingCard = ({ passing }) => {
  const { t } = useTranslation();

  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>
          {t("Passing")} #{passing.num_passing}
        </Card.Title>
        <Card.Text>
          <strong>Bib:</strong> {passing.bib} <br />
          <strong>Chip:</strong> {passing.chip} <br />
          <strong>ID:</strong> {passing.participant_id} <br />
          <strong>Timestamp:</strong> {passing.timestamp} <br />
          <strong>{t("Localization")}:</strong> {passing.latitude}-{passing.longitude} <br />
          <strong>{t("Status")}:</strong> {passing.status}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default PassingCard;
