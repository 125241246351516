import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import * as yup from "yup";

import classes from "./ChangePassword.module.css";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";

const schema = yup
  .object()
  .shape({
    currentPassword: yup.string().required(i18n.t("No password provided")),
    newPassword: yup.string().required(i18n.t("New password is required")),
    passwordRepeat: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], i18n.t("Passwords must match")),
  })
  .required();

const ChangePassword = (props) => {
  const handleUpdatePassword = props.handleUpdatePassword;
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const errorHandler = (error) => {
    console.error(error);
  };

  const submitHandler = (data) => {
    const { currentPassword, newPassword } = data;

    handleUpdatePassword(currentPassword, newPassword);
    reset();
  };

  return (
    <div className={classes["password-container"]}>
      <div>
        <h4>{t("Change password")}</h4>
      </div>
      <Form
        name="changePasswordForm"
        noValidate
        onSubmit={handleSubmit(submitHandler, errorHandler)}
      >
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridCurrentPassword">
            <Form.Control
              type="password"
              placeholder={t("Current password")}
              {...register("currentPassword")}
            />
            {errors.currentPassword && (
              <Form.Text className="text-danger">
                {errors.currentPassword.message}
              </Form.Text>
            )}
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridNewPassword">
            <Form.Control
              type="password"
              placeholder={t("New password")}
              {...register("newPassword")}
            />
            {errors.newPassword && (
              <Form.Text className="text-danger">
                {errors.newPassword.message}
              </Form.Text>
            )}
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridRepeat">
            <Form.Control
              type="password"
              placeholder={t("Repeat")}
              {...register("passwordRepeat")}
            />
            {errors.passwordRepeat && (
              <Form.Text className="text-danger">
                {errors.passwordRepeat.message}
              </Form.Text>
            )}
          </Form.Group>
        </Row>
        <Button variant="primary" type="submit">
          {/* {isChangingPassword ? (
            <Spinner as="span" animation="border" size="sm" />
          ) : (
            t("Change password")
          )} */}
          {t("Change password")}
        </Button>
      </Form>
    </div>
  );
};

export default ChangePassword;
