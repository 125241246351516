export const uiInitialValue = {
  drawerOpen: false,
  activeTabs: [],
  activeTabsData: [],
  tabIndex: 1,
  activeTabIndex: '',
  selectedRoute: '',
  showAddPassingModal: false,
  showCheckpointModal: false,
  checkpointModalData: null,
  refreshCheckpoints: false,
  showRaceModal: false,
  raceModalData: null,
  showDeviceModal: false,
  showSegmentModal: false,
  segmentModalData: null,
  deviceModalData: null,
  refreshRaces: false,
  showChangeModal: false,
  changeModalTitle: '',
  changeModalDescription: '',
  changeModalAction: '',
  changeModalOptions: [],
  showConfirmationModal: false,
  confirmationModalTitle: '',
  confirmationModalDescription: '',
  confirmationModalAction: '',
  showLoginModal: false,
  showNewEventModal: false,
  passingsCursors: [],
  lastPassingsCursors: [],
};
