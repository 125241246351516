import classes from "./AuthPage.module.css";

import AuthForm from "../components/Auth/AuthForm";

import rufusLogo from "../assets/cloud-logo.png";

const AuthPage = () => {
  return (
    <div className={classes.bg}>
      {/* <AuthForm /> */}
      <div className={classes.authContainer}>
        <img src={rufusLogo} alt="RUFUS Cloud Logo" className={classes.logo} />
        <AuthForm />
      </div>
    </div>
  );
};

export default AuthPage;
