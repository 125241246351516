export const authInitialValue = {
  email: null,
  idToken: null,
  accessToken: null,
  refreshToken: null,
  isAuthenticated: false,
  companyApiToken: null,
  eventToken: null,
  userType: null,
  name: null,
  lastname: null,
  company: null,
  preferredLanguage: null,
  imageUrl: null,
};
