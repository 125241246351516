import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { ApolloProvider } from "@apollo/client";
import client from "./apolloClient";

import { AuthProvider } from "./context/AuthContext";
import { UserProvider } from "./context/UserContext";
import { SubscriptionProvider } from "./context/SubscriptionContext";
import { NotificationProvider } from "./context/NotificationContext";

// Importing the Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";

import "./index.css";
import "./i18n/i18n";
import App from "./App";
import awsExports from "./aws-exports";

Amplify.configure(awsExports);

// Mute console methods in production
if (process.env.REACT_APP_BUILD_ENV === "prod") {
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
  console.info = () => {};
  console.debug = () => {};
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ApolloProvider client={client}>
    <AuthProvider>
      <UserProvider>
        <NotificationProvider>
          <SubscriptionProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </SubscriptionProvider>
        </NotificationProvider>
      </UserProvider>
    </AuthProvider>
  </ApolloProvider>
);
