import { ApolloClient, ApolloLink, HttpLink } from "@apollo/client";

import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import { createAuthLink } from "aws-appsync-auth-link";

import { cache } from "./cache";

const url = process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT; //awsExports.aws_appsync_graphqlEndpoint;
const region = process.env.REACT_APP_APPSYNC_GRAPHQL_REGION; //awsExports.aws_appsync_region;
const auth = {
  type: process.env.REACT_APP_APPSYNC_AUTH_TYPE, //awsExports.aws_appsync_authenticationType,
  apiKey: process.env.REACT_APP_APPSYNC_API_KEY, //awsExports.aws_appsync_apiKey,
};

const httpLink = new HttpLink({ uri: url });
const awsLink = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
]);

const client = new ApolloClient({
  link: awsLink,
  cache,
  connectToDevTools: true,
});

export default client;
