import { Fragment, useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import countryList from 'country-list';

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";

import { UPDATE_USER } from "../../graphql/mutations";

import { useAuth } from "../../context/AuthContext";
import { useUser } from "../../context/UserContext";

import { USERTYPES } from "../../store/models/user-types";

import Logout from "./Logout";
import ChangePassword from "./ChangePassword";

import { ToastError, ToastSuccess } from "../Helpers/ToastHelper";

// Regular expression for international phone numbers in E.164 format
const phoneRegExp = /^\+?[1-9]\d{1,14}$/;

const schema = yup
  .object()
  .shape(
    {
      company: yup
        .string()
        .nullable()
        .required(i18n.t("A company is required")),
      email: yup
        .string()
        .email(i18n.t("Invalid email address"))
        .required(i18n.t("An email is required")), //.typeError('An email is required typeError'),
      name: yup.string().nullable().required(i18n.t("A name is required")),
      lastname: yup.string().nullable(),
      city: yup.string().nullable(),
      country: yup.string().nullable(),
      telephone: yup
        .string()
        .nullable()
        .notRequired()
        .matches(phoneRegExp, {
          message: i18n.t("Phone number is not valid"),
          excludeEmptyString: true,
        }),
      preferred_language: yup.string().nullable(),
    },
    [
      // Add Cyclic deps here because when require itself
      ["telephone", "telephone"],
    ]
  )
  .required();

const Profile = () => {
  const { t } = useTranslation();
  const { userData } = useUser();
  const {
    handleUpdateUserAttribute,
    authState,
    handleSendUserAttributeVerificationCode,
    handleConfirmUserAttribute,
    handleDeleteUserAttribute,
    handleUpdatePassword,
    handleSignOut,
  } = useAuth();
  const { isEmailVerificationPending, isPhoneVerificationPending } = authState;
  const api_token = userData?.api_token || null;
  const defaultValues = useMemo(() => {
    let defaultValues = { ...userData };

    if (authState.tempEmail) {
      defaultValues = { ...userData, email: authState.tempEmail };
    }

    if (authState.tempPhone) {
      defaultValues = { ...userData, telephone: authState.tempPhone };
    } else {
      if (userData?.country_code) {
        defaultValues = {
          ...userData,
          telephone: `${userData?.country_code}${userData?.telephone}`,
        };
      }
    }

    return defaultValues;
  }, [userData, authState.tempEmail, authState.tempPhone]);
  const [updateUser, { loading: isSavingProfile }] = useMutation(UPDATE_USER, {
    // refetchQueries: ["GetUserByCredentials"],
    onCompleted(data) {
      if (
        data.updateUser === "UserUpdateOk" ||
        data.updateUser === "NothingToUpdate"
      ) {
        ToastSuccess("ProfileUpdated");
      }
    },
    onError(error) {
      console.error(error);
      ToastError(error);
    },
  });
  const [changeEmailState, setChangeEmailState] = useState("change");
  const [emailDisabled, setEmailDisabled] = useState(true);
  const [phoneDisabled, setPhoneDisabled] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange", //onSubmit
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
    resolver: yupResolver(schema),
  });
  const watchEmail = watch("email");
  const watchEmailConfirmCode = watch("emailConfirmCode");
  const watchPhoneConfirmCode = watch("phoneConfirmCode");
  const watchPhoneNumber = watch("telephone");
  const countries = countryList.getNames();

  const errorHandler = (error) => {
    console.error(error);
  };

  const submitHandler = (data) => {
    const {
      api_token,
      name,
      lastname,
      company,
      email_notifications,
      sms_notifications,
      preferred_language,
      country,
      city,
      image_url,
    } = data;

    // Construct the user object
    const user = {
      api_token, // Required
      name,
      lastname,
      company,
      email_notifications: email_notifications || null, // Ensure AWSJSON type
      sms_notifications: sms_notifications || null, // Ensure AWSJSON type
      preferred_language,
      country,
      city,
      image_url,
    };

    updateUser({
      variables: {
        user,
      },
      refetchQueries: ["GetUserByCredentials"],
    });
  };

  const changeEmailHandler = async () => {
    if (changeEmailState === "change") {
      setChangeEmailState("save");
      setEmailDisabled(false);
    }

    if (changeEmailState === "save") {
      if (errors.email) return;

      if (watchEmail !== userData.email) {
        // Asumo siempre correcto
        handleUpdateUserAttribute("email", watchEmail, api_token, updateUser);
      } else {
        setChangeEmailState("change");
      }
    }
  };

  const changePhoneNumberHandler = () => {
    if (errors.telephone) return;

    handleUpdateUserAttribute(
      "phone_number",
      `${watchPhoneNumber}`,
      api_token,
      updateUser
    );
  };

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  useEffect(() => {
    if (changeEmailState === "save") {
      setEmailDisabled(false);
    } else if (changeEmailState === "change") {
      setEmailDisabled(true);
    }
  }, [changeEmailState]);

  useEffect(() => {
    isEmailVerificationPending && setEmailDisabled(true);
    !isEmailVerificationPending && setChangeEmailState("change");
  }, [isEmailVerificationPending]);

  useEffect(() => {
    if (!userData?.telephone) setPhoneDisabled(false);
    if (userData?.telephone && isPhoneVerificationPending)
      setPhoneDisabled(true);
    if (userData?.telephone && !isPhoneVerificationPending)
      setPhoneDisabled(false);
  }, [userData?.telephone, isPhoneVerificationPending]);

  return (
    <Fragment>
      <Form noValidate onSubmit={handleSubmit(submitHandler, errorHandler)}>
        {/* First Row */}
        <Row className="mb-3">
          {/* Company Field */}
          <Form.Group as={Col} xs={12} md={6} controlId="formGridCompany">
            <Form.Label>
              {userData?.user_type !== USERTYPES.OWNER
                ? t("This account is managed by:")
                : t("My Company")}
            </Form.Label>
            <Form.Control
              type="text"
              placeholder={t("My sport business")}
              disabled={userData?.user_type !== USERTYPES.OWNER}
              {...register("company")}
            />
            {errors.company && (
              <Form.Text className="text-danger">
                {errors.company.message}
              </Form.Text>
            )}
          </Form.Group>

          {/* Email Field */}
          <Form.Group as={Col} xs={12} md={6} controlId="formGridEmail">
            <Form.Label>{t("Email")}</Form.Label>
            <Form.Control
              type="email"
              placeholder="you@yourcompany.com"
              disabled={emailDisabled}
              {...register("email")}
            />
            {isEmailVerificationPending && (
              <div className="mt-2">
                <Form.Control
                  type="text"
                  placeholder="123456"
                  {...register("emailConfirmCode")}
                  className="mb-2"
                />
                <Button
                  variant="link"
                  onClick={() =>
                    handleConfirmUserAttribute("email", watchEmailConfirmCode)
                  }
                >
                  {t("confirm")}
                </Button>
                <Button
                  variant="link"
                  onClick={() =>
                    handleSendUserAttributeVerificationCode("email")
                  }
                >
                  {t("send again")}
                </Button>
              </div>
            )}
            {!isEmailVerificationPending && (
              <Button variant="link" onClick={changeEmailHandler}>
                {changeEmailState}
              </Button>
            )}
            {errors.email && (
              <Form.Text className="text-danger">
                {errors.email.message}
              </Form.Text>
            )}
          </Form.Group>
        </Row>

        {/* Second Row */}
        <Row className="mb-3">
          {/* Name Field */}
          <Form.Group as={Col} xs={12} md={6} controlId="formGridName">
            <Form.Label>{t("Name")}</Form.Label>
            <Form.Control type="text" {...register("name")} />
            {errors.name && (
              <Form.Text className="text-danger">
                {errors.name.message}
              </Form.Text>
            )}
          </Form.Group>

          {/* Lastname Field */}
          <Form.Group as={Col} xs={12} md={6} controlId="formGridLastname">
            <Form.Label>{t("Lastname")}</Form.Label>
            <Form.Control type="text" {...register("lastname")} />
            {errors.lastname && (
              <Form.Text className="text-danger">
                {errors.lastname.message}
              </Form.Text>
            )}
          </Form.Group>
        </Row>

        {/* Third Row */}
        <Row className="mb-3">
          {/* City Field */}
          <Form.Group as={Col} xs={12} md={6} controlId="formGridCity">
            <Form.Label>{t("City")}</Form.Label>
            <Form.Control type="text" {...register("city")} />
          </Form.Group>

          {/* Country Field */}
          <Form.Group as={Col} xs={12} md={6} controlId="formGridCountry">
            <Form.Label>{t("Country")}</Form.Label>
            <Form.Select {...register("country")}>
              <option value="">{t("Select a country")}</option>
              {countries.map((country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Row>

        {/* Fourth Row */}
        <Row className="mb-3">
          {/* Phone Number Field */}
          <Form.Group as={Col} xs={12} md={6} controlId="formGridPhoneNumber">
            <Form.Label>{t("Phone number")}</Form.Label>
            <Form.Control
              type="text"
              placeholder="123 456 678"
              disabled={phoneDisabled}
              {...register("telephone")}
            />
            {isPhoneVerificationPending && userData?.telephone && (
              <div className="mt-2">
                <Form.Control
                  type="text"
                  placeholder="123456"
                  {...register("phoneConfirmCode")}
                  className="mb-2"
                />
                <Button
                  variant="link"
                  onClick={() =>
                    handleConfirmUserAttribute(
                      "phone_number",
                      watchPhoneConfirmCode
                    )
                  }
                >
                  {t("confirm")}
                </Button>
                <Button
                  variant="link"
                  onClick={() =>
                    handleSendUserAttributeVerificationCode("phone_number")
                  }
                >
                  {t("send again")}
                </Button>
                <Button
                  variant="link"
                  onClick={() =>
                    handleDeleteUserAttribute(
                      "phone_number",
                      api_token,
                      updateUser
                    )
                  }
                >
                  {t("remove")}
                </Button>
              </div>
            )}
            {watchPhoneNumber !== userData?.telephone && (
              <Button variant="link" onClick={changePhoneNumberHandler}>
                {t("save")}
              </Button>
            )}
            {errors.telephone && (
              <Form.Text className="text-danger">
                {errors.telephone.message}
              </Form.Text>
            )}
          </Form.Group>

          {/* Preferred Language Field */}
          <Form.Group
            as={Col}
            xs={12}
            md={6}
            controlId="formGridPreferredLanguage"
          >
            <Form.Label>{t("Preferred language")}</Form.Label>
            <Form.Select
              aria-label="Preferred language"
              {...register("preferred_language")}
            >
              <option value="es">Español</option>
              <option value="en">English</option>
              <option value="pt">Português</option>
            </Form.Select>
          </Form.Group>
        </Row>

        {/* Submit Button */}
        <Row className="mb-3">
          <Col xs={12}>
            <Button variant="primary" type="submit" disabled={isSavingProfile}>
              {isSavingProfile ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  {t("Saving...")}
                </>
              ) : (
                t("Save Profile")
              )}
            </Button>
          </Col>
        </Row>
      </Form>
      <Row className="mb-3">
        <ChangePassword
          handleUpdatePassword={handleUpdatePassword}
        ></ChangePassword>
      </Row>
      <Row className="mb-3">
        <Logout handleSignOut={handleSignOut}></Logout>
      </Row>
    </Fragment>
  );
};

export default Profile;
