import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { parse, formatDistanceToNow } from "date-fns";

import classes from "./DeviceCard.module.css";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Badge from "react-bootstrap/Badge";

import imgRufusAndroid from "../../assets/timing-app-card.png";
import imgCloudBox from "../../assets/cloudbox-card.png";
import imgCustomDevice from "../../assets/custom-device-card-2.png";

import { Icon } from "../UI/Icon";

const generateGoogleMapsLink = (latitude, longitude, options = {}) => {
  const lat = parseFloat(latitude);
  const lng = parseFloat(longitude);

  if (isNaN(lat) || isNaN(lng)) {
    // throw new Error('Invalid latitude or longitude');
    return null;
  }

  const { zoom = 15, mapType = "m" } = options;

  // Construct the URL with additional parameters
  return `https://www.google.com/maps/@${lat},${lng},${zoom}z?hl=en&maptype=${mapType}`;
};

const DeviceCard = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { device } = props;

  // Extract device main properties
  const id = device?.id;
  const type = device?.type;
  const alias = device?.alias;
  const model = device?.model;
  const eventName = device?.event_name;
  const serialNumber = device?.serial_number;
  const numOfSessions = device?.numOfSessions;
  const lastPassingTimestampRaw = device.last_passing_timestamp;
  const isCloudBox = device?.isCloudBox;
  const locationFromPassing = {
    latitudeDecimal: device?.last_passing_known_latitude,
    longitudeDecimal: device?.last_passing_known_longitude,
    lastSync: device?.last_passing_location_timestamp?.endsWith("Z")
      ? device?.last_passing_location_timestamp?.slice(0, -1)
      : device?.last_passing_location_timestamp,
    googleMapsLink: generateGoogleMapsLink(
      device?.last_passing_known_latitude,
      device?.last_passing_known_longitude
    ),
    statusGps: "LOCATION_FROM_PASSING",
    statusGpsMessage: "Last known position from passing location",
    locationFromPassing: true,
  };

  // Extract device status properties
  const status = device?.status;
  const isConnected = status?.connected;
  const startMode = status?.startMode;
  const disconnectReason = status?.disconnectReason;
  const hasPower = status?.hasPower;
  const batteryPercentage = parseFloat(status?.batteryPercentage) || 0;
  const batteryVolts = status?.batteryVolts;
  const cpuTemperature = status?.cpuTemperature;
  const location = status?.location ? status.location : locationFromPassing;
  const lastPassingSanitizedTimestamp = status?.lastPassingTimestamp
    ? status.lastPassingTimestamp
    : lastPassingTimestampRaw;
  const lastPassingTimestamp = lastPassingSanitizedTimestamp?.endsWith("Z")
    ? lastPassingSanitizedTimestamp?.slice(0, -1)
    : lastPassingSanitizedTimestamp;

  // Extract location data
  const latitude = parseFloat(location?.latitudeDecimal);
  const longitude = parseFloat(location?.longitudeDecimal);
  const googleMapsLink = location?.googleMapsLink;
  const lastSync = location?.lastSync;
  const statusGps = location?.statusGps;
  const statusGpsMessage = location?.statusGpsMessage;

  const clickHandler = (id) => {
    navigate("/devices/" + id);
  };

  const getBatteryIcon = () => {
    if (!isCloudBox) return null;

    // Ensure batteryPercentage and batteryVolts are valid numbers
    const batteryPercentRaw = parseFloat(batteryPercentage);
    const batteryVolt = parseFloat(batteryVolts);

    if (isNaN(batteryPercentRaw) || isNaN(batteryVolt)) {
      return null; // Return null or a placeholder if data is invalid
    }

    // Round the battery percentage to the nearest whole number
    const batteryPercent = Math.round(batteryPercentRaw);

    // Determine the appropriate battery icon based on battery percentage and charging status
    let iconName;
    let color = "grey"; // Default icon color
    let charging = null;

    if (hasPower) {
      iconName = "BatteryCharging";
      color = "green";
      charging = "CHARGING";
    } else if (batteryPercent >= 75) {
      iconName = "BatteryFull";
      color = "green";
    } else if (batteryPercent >= 50) {
      iconName = "BatteryHalf";
      color = "green";
    } else if (batteryPercent >= 25) {
      iconName = "Battery";
      color = "orange";
    } else {
      iconName = "Battery";
      color = "red";
    }

    if (!isConnected) color = "grey";

    const iconSize = 25; // Adjust the icon size as needed

    // Tooltip content
    const tooltip = charging ? (
      <Tooltip id={`tooltip-battery-${iconName}`}>
        {`${charging}`} {<br />} {`${batteryPercent}% - ${batteryVolt}V`}
      </Tooltip>
    ) : (
      <Tooltip id={`tooltip-battery-${iconName}`}>
        {`${batteryPercent}% - ${batteryVolt}V`}
      </Tooltip>
    );

    return (
      <OverlayTrigger placement="top" overlay={tooltip}>
        <span className={`d-flex align-items-center ${classes.pointer}`}>
          <Icon
            iconName={iconName}
            color={color}
            size={iconSize}
            className="align-top"
          />
        </span>
      </OverlayTrigger>
    );
  };

  const getConnectionIcon = () => {
    if (!isCloudBox) return null;

    // Determine the appropriate battery icon based on battery percentage and charging status
    let iconName;
    let color = "grey"; // Default icon color
    let tooltipText = null;

    if (isConnected && startMode) {
      iconName = "Wifi";
      color = "blue";
      tooltipText = `Online and reading`;
    }
    if (isConnected && !startMode) {
      iconName = "Wifi";
      color = "green";
      tooltipText = `Online`;
    }
    if (!isConnected) {
      iconName = "WifiOff";
      color = "grey";
      tooltipText = `Offline ${disconnectReason}`;
    }

    // Tooltip content
    const tooltip = (
      <Tooltip id={`tooltip-connection}`}>{`${tooltipText}`}</Tooltip>
    );

    return (
      <OverlayTrigger placement="top" overlay={tooltip}>
        <span className={`d-flex align-items-center ${classes.pointer}`}>
          <Icon iconName={iconName} color={color} size="25" />
        </span>
      </OverlayTrigger>
    );
  };

  const getTemperatureIcon = () => {
    if (!isCloudBox) return null;

    // Round the battery percentage to the nearest whole number
    const temp = Math.round(cpuTemperature);

    // Determine the appropriate battery icon based on battery percentage and charging status
    let iconName;
    let color = "grey"; // Default icon color

    if (temp >= 75) {
      iconName = "ThermometerHigh";
      color = "red";
    } else if (temp >= 65) {
      iconName = "ThermometerHalf";
      color = "orange";
    } else {
      iconName = "ThermometerLow";
      color = "blue";
    }

    if (!isConnected) color = "grey";

    const iconSize = 25; // Adjust the icon size as needed

    // Tooltip content
    const tooltip = (
      <Tooltip id={`tooltip-cputemp`}>{`CPU Temp: ${temp}C`}</Tooltip>
    );

    return (
      <OverlayTrigger placement="top" overlay={tooltip}>
        <span className={`d-flex align-items-center ${classes.pointer}`}>
          <Icon
            iconName={iconName}
            color={color}
            size={iconSize}
            className="align-top"
          />
        </span>
      </OverlayTrigger>
    );
  };

  const getLocationIcon = () => {
    if (!latitude || !longitude) return null;

    let iconName;
    let color = "grey";

    if (isCloudBox) {
      if (statusGps === "STARTING" || statusGps === "SEARCHING") {
        color = "orange";
      }

      if (statusGps === "SYSTEM_LOCATED") {
        color = "green";
      }

      if (!isConnected) color = "grey";
    }

    const elapsedTime = lastSync
      ? formatDistanceToNow(new Date(lastSync), { addSuffix: true })
      : null;

    const tooltip = (
      <Tooltip id={`tooltip-gps`}>
        {`${statusGps}`} <br />
        {`Last Sync: ${elapsedTime}`}
      </Tooltip>
    );

    return (
      <OverlayTrigger placement="top" overlay={tooltip}>
        <a
          className="d-flex align-items-center"
          href={googleMapsLink ? googleMapsLink : "#"}
          target="_blank"
          rel="noreferrer"
        >
          <Icon
            iconName={"GeoAltFill"}
            color={color}
            size="23"
            className="align-top"
          />
        </a>
      </OverlayTrigger>
    );
  };

  const getCardHeader = () => {
    const textStyle = {
      fontSize: `13px`,
      marginLeft: "5px",
      marginRight: "5px",
      color: "grey",
    };

    if (isCloudBox)
      return (
        <>
          <span>{"CloudBox"}</span>
          <span style={textStyle}>{serialNumber}</span>
        </>
      );
    if (type === "Android App") return `${model}`;
    if (type === "Custom")
      return (
        <>
          <span>{model}</span>
          <span style={textStyle}>{serialNumber}</span>
        </>
      );

    return (
      <>
        <span>{t("Unknown Device")}</span>
      </>
    );
  };

  const getDeviceEvent = () => {
    if (eventName) {
      return (
        <span className="d-flex align-items-center">
          <Badge bg="danger">
            <Icon
              iconName={"CalendarEvent"}
              size={"13"}
              className="align-top"
            />
            {` ${eventName}`}
          </Badge>
        </span>
      );
    }
    return (
      <span className="d-flex align-items-center">
        <Badge bg="success">{t(`Available`)}</Badge>
      </span>
    );
  };

  const getDeviceSessions = () => {
    if (!numOfSessions || numOfSessions === 0) {
      return t("No sessions available");
    } else if (numOfSessions === 1) {
      return t("1 session available");
    } else if (numOfSessions > 1) {
      return numOfSessions + " " + t(`sessions available`);
    }
  };

  const getElapsedPassing = () => {
    // Calculate elapsed time since the last update
    if (lastPassingTimestamp) {
      const elapsedTime = formatDistanceToNow(new Date(lastPassingTimestamp), {
        addSuffix: true,
      });

      if (elapsedTime)
        return <span>{`${t("Last passing ")} ${elapsedTime}`}</span>;
    }
    return <span>{t("Last passing never")}</span>;
  };

  const getCardImage = () => {
    if (isCloudBox) return imgCloudBox;
    if (type === "Android App") return imgRufusAndroid;

    return imgCustomDevice;
  };

  return (
    <Col>
      <Card className={classes.card} key="front">
        <Card.Header className="d-flex justify-content-between align-items-center">
          {/* Left Side: Connected Icon and Device Type */}
          <div className="d-flex align-items-center">
            {getConnectionIcon()}

            <span style={{ marginLeft: "5px" }}>{getCardHeader()}</span>
          </div>

          {/* Right Side: CPU Temperature and Battery Icon */}
          <div className="d-flex align-items-center">
            {getTemperatureIcon()}

            {getBatteryIcon()}
          </div>
        </Card.Header>

        {/* Rest of the card remains the same */}
        <Card.Img
          variant="top"
          src={getCardImage()}
          onClick={() => clickHandler(id)}
        />

        <Card.Body style={{ flex: "1 0 auto" }}>
          <Card.Title className="d-flex justify-content-between align-items-center">
            <span className={classes.alias} onClick={() => clickHandler(id)}>
              {alias}
            </span>

            {getLocationIcon()}
          </Card.Title>
          <Card.Subtitle className="mb-2">{getDeviceEvent()}</Card.Subtitle>

          <Card.Text className="text-muted">{getDeviceSessions()}</Card.Text>
        </Card.Body>

        <Card.Footer className="text-muted d-flex justify-content-between align-items-center">
          {getElapsedPassing()}
        </Card.Footer>
      </Card>
    </Col>
  );
};

export default DeviceCard;
