import toast from "react-hot-toast";
import i18n from "i18next";

import { infoMessages, successMessages, errorMessages } from "./ToastMessages"; // Adjust the path
import Button from "react-bootstrap/Button";

export const ToastInfo = (code, payload = null) => {
  const message = infoMessages[code] ? infoMessages[code](payload) : code;
  const defaultOptions = {
    duration: 3000,
    position: "bottom-left", // Centered at the bottom
    style: {
      backgroundColor: "#cce5ff", // Light blue background
      color: "#004085", // Dark blue text
    },
  };

  toast(message, defaultOptions);
};

export const ToastInfoDismiss = (code, payload = null) => {
  const message = infoMessages[code] ? infoMessages[code](payload) : code;
  const defaultOptions = {
    duration: Infinity, // Make the toast permanent
    position: "bottom-center", // Centered at the bottom
    style: {
      backgroundColor: "#cce5ff", // Light blue background
      color: "#004085", // Dark blue text
    },
  };

  toast(
    (t) => (
      <>
        <span style={{ flex: 1, marginRight: "10px", wordBreak: "break-word" }}>
          {message}
        </span>
        <Button
          variant="link"
          onClick={() => toast.dismiss(t.id)}
          style={{
            color: "#155724",
            textDecoration: "none",
            fontSize: "18px",
            padding: "0",
            marginTop: "-15px",
            marginRight: "-10px",
          }}
        >
          ×
        </Button>
      </>
    ),
    defaultOptions
  );
};

export const ToastError = (errorOrMessage, payload = null) => {
  let errorCode = "UnknownError";
  let message = "An error occurred.";
  const defaultOptions = {
    duration: 3000,
    position: "bottom-left", // Centered at the bottom
    style: {
      backgroundColor: "#f8d7da", // Light red background
      color: "#721c24", // Dark red text
    },
  };

  if (errorOrMessage) {
    if (typeof errorOrMessage === "object") {
      errorCode = errorOrMessage.code || errorOrMessage.name || "UnknownError";
      const messageCode = errorOrMessage.message || message;

      message = errorMessages[messageCode]
        ? errorMessages[messageCode](payload)
        : errorMessages[errorCode]
        ? errorMessages[errorCode](payload)
        : messageCode;
    } else if (typeof errorOrMessage === "string") {
      const colonIndex = errorOrMessage.indexOf(":");
      if (colonIndex !== -1) {
        errorCode = errorOrMessage.slice(0, colonIndex);
        message = errorOrMessage.slice(colonIndex + 1).trim();
      }

      message = errorMessages[errorOrMessage]
        ? errorMessages[errorOrMessage](payload)
        : errorMessages[errorCode]
        ? errorMessages[errorCode](payload)
        : errorOrMessage;
    }
  }

  toast.error(message, defaultOptions);
};

export const ToastErrorDismiss = (code, payload = null) => {
  const message = errorMessages[code] ? errorMessages[code](payload) : code;
  const defaultOptions = {
    duration: Infinity, // Make the toast permanent
    position: "bottom-center", // Centered at the bottom
    style: {
      backgroundColor: "#f8d7da", // Light red background
      color: "#721c24", // Dark red text
    },
  };

  toast(
    (t) => (
      <>
        <span style={{ flex: 1, marginRight: "10px", wordBreak: "break-word" }}>
          {message}
        </span>
        <Button
          variant="link"
          onClick={() => toast.dismiss(t.id)}
          style={{
            color: "#721c24",
            textDecoration: "none",
            fontSize: "18px",
            padding: "0",
            marginTop: "-15px",
            marginRight: "-10px",
          }}
        >
          ×
        </Button>
      </>
    ),
    defaultOptions
  );
};

export const ToastSuccess = (code, payload = null) => {
  const message = successMessages[code] ? successMessages[code](payload) : code;
  const defaultOptions = {
    duration: 3000,
    position: "bottom-left", // Centered at the bottom
    style: {
      backgroundColor: "#d4edda", // Light green background
      color: "#155724", // Dark green text
    },
  };

  toast.success(message, defaultOptions);
};

export const ToastSuccessDismiss = (code, payload = null) => {
  const message = successMessages[code] ? successMessages[code](payload) : code;
  const defaultOptions = {
    duration: Infinity, // Make the toast permanent
    position: "bottom-center", // Centered at the bottom
    style: {
      backgroundColor: "#d4edda", // Light green background
      color: "#155724", // Dark green text
    },
  };

  toast(
    (t) => (
      <>
        <span style={{ flex: 1, marginRight: "10px", wordBreak: "break-word" }}>
          {message}
        </span>
        <Button
          variant="link"
          onClick={() => toast.dismiss(t.id)}
          style={{
            color: "#155724",
            textDecoration: "none",
            fontSize: "18px",
            padding: "0",
            marginTop: "-15px",
            marginRight: "-10px",
          }}
        >
          ×
        </Button>
      </>
    ),
    defaultOptions
  );
};
