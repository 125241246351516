import { useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";

import classes from "./AllPassings.module.css";

import { CloudDownload } from "react-bootstrap-icons";
import Badge from "react-bootstrap/Badge";
import Spinner from "react-bootstrap/Spinner";

import PassingsTable from "./PassingsTable";
import NoPassings from "./NoPassings";
import Loading from "../Loading/Loading";

const AllPassings = (props) => {
  const { t } = useTranslation();
  const [csvPassings, setCsvPassings] = useState([]);
  const { currentSession, passings, loadingPassings } = props;

  // Only calculate numOfPassings when passings are loaded
  const numOfPassings = passings ? passings.length : 0;

  const csvFileName =
    currentSession && currentSession.alias
      ? currentSession.alias.includes(".csv")
        ? currentSession.alias
        : `${currentSession.alias}.csv`
      : "current_session.csv";

  const downloadHandler = (event, done) => {
    if (passings && passings.length > 0) {
      const csvData = passings.map(
        ({ _id, token_session, event_token, ...rest }) => rest
      );
      setCsvPassings(csvData);
    }
    done();
  };

  return (
    <>
      <div>
        <h1 className="text-truncate">{currentSession.alias}</h1>
        <h6>{currentSession.active && <Badge bg="danger">ACTIVE</Badge>}</h6>
      </div>
      <span>
        {numOfPassings <= 0 && (
          <>
            <span>{t("empty-session-info")}</span>
          </>
        )}

        {numOfPassings > 0 && (
          <>
            {" "}
            <span>
              {t("There are")} {numOfPassings} {t("passings availables for the current session.")}{" "}
            </span>
            <CSVLink
              filename={csvFileName}
              data={csvPassings}
              asyncOnClick={true}
              onClick={downloadHandler}
            >
              {t("Download CSV file")} <CloudDownload />
            </CSVLink>
          </>
        )}
      </span>
      {loadingPassings && <Loading />}
      {!loadingPassings && passings && passings.length > 0 && (
        <PassingsTable passings={passings} />
      )}
      {!loadingPassings && passings && passings.length === 0 && <NoPassings />}
    </>
  );
};

export default AllPassings;

// import { useState } from "react";
// import { CSVLink } from "react-csv";
// import { useTranslation } from "react-i18next";

// import classes from "./AllPassings.module.css";

// import { CloudDownload } from "react-bootstrap-icons";
// import Badge from "react-bootstrap/Badge";

// import PassingsTable from "./PassingsTable";
// import NoPassings from "./NoPassings";

// const AllPassings = (props) => {
//   const { t } = useTranslation();
//   const [csvPassings, setCsvPassings] = useState([]);
//   const { currentSession, passings, loadingPassings } = props;
//   const numOfPassings = passings ? passings.length : 0;
//   const csvFileName =
//     currentSession && currentSession.alias
//       ? currentSession.alias.includes(".csv")
//         ? currentSession.alias
//         : currentSession.alias.replace(".csv", "")
//       : "current_session.csv";

//   const downloadHandler = (event, done) => {
//     if (passings && passings.length > 0) {
//       const csvData = passings.map(
//         ({ _id, token_session, event_token, ...rest }) => {
//           return rest;
//         }
//       );
//       setCsvPassings(csvData);
//     }
//     done();
//   };

//   return (
//     <>
//       <div className={classes.edit}>
//         <h1>
//           {t("Passings from")} {currentSession.alias}
//         </h1>{" "}
//         <h6>{currentSession.active && <Badge bg="danger">ACTIVE</Badge>}</h6>
//       </div>
//       <span>
//         {t("There are")} {numOfPassings}{" "}
//         {t("passings availables for the current session.")}{" "}
//         {numOfPassings > 0 && (
//           <CSVLink
//             filename={csvFileName}
//             data={csvPassings}
//             asyncOnClick={true}
//             onClick={downloadHandler}
//           >
//             {t("Download CSV file")} <CloudDownload />
//           </CSVLink>
//         )}
//       </span>
//       {passings && passings.length > 0 && <PassingsTable passings={passings} />}
//       {passings && passings.length === 0 && <NoPassings />}
//     </>
//   );
// };

// export default AllPassings;
