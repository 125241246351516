import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  signIn,
  signUp,
  confirmSignUp,
  resetPassword,
  confirmResetPassword,
  resendSignUpCode,
  getCurrentUser,
} from "aws-amplify/auth";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import YupPassword from "yup-password";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";

import { REGISTER_USER } from "../../graphql/mutations";

import { useAuth } from "../../context/AuthContext";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";

import classes from "./AuthForm.module.css";

import { ToastSuccess, ToastError } from "../Helpers/ToastHelper";

// Extend Yup with password requirements
YupPassword(yup);

// Define validation schemas
const schemaSignIn = yup.object().shape({
  username: yup
    .string()
    .nullable()
    .email("Invalid email address")
    .required("An email is required"),
  password: yup
    .string()
    .minSymbols(0)
    .nullable()
    .required("A password is required"),
});

const schemaSignUp = yup.object().shape({
  username: yup
    .string()
    .nullable()
    .email("Invalid email address")
    .required("An email is required"),
  password: yup
    .string()
    .nullable()
    .password()
    .minSymbols(0)
    .required("A password is required"),
});

const schemaConfirm = yup.object().shape({
  username: yup
    .string()
    .nullable()
    .email("Invalid email address")
    .required("An email is required"),
  code: yup.string().nullable().required("A confirmation code is required"),
});

const schemaForgot = yup.object().shape({
  username: yup
    .string()
    .nullable()
    .email("Invalid email address")
    .required("An email is required"),
});

const schemaReset = yup.object().shape({
  username: yup
    .string()
    .nullable()
    .email("Invalid email address")
    .required("An email is required"),
  code: yup.string().nullable().required("A reset code is required"),
  password: yup
    .string()
    .nullable()
    .password()
    .minSymbols(0)
    .required("A new password is required"),
});

const AuthForm = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { invitation } = useParams();
  const { authState, setAuthState, fetchUser, handleSignOut } = useAuth();
  const [registerUser] = useMutation(REGISTER_USER, {
    onCompleted(data) {
      console.log(data);
      // if (data.registerUser === "SUCCESS") {
      //   ToastSuccess("InvitationSent");
      // }
    },
    onError(error) {
      console.error(error);
      ToastError(error);
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const formState = location.pathname;

  const getFormType = () => {
    if (formState.includes("/signup")) return "signup";
    if (formState.includes("/confirm")) return "confirm";
    if (formState.includes("/forgot")) return "forgot";
    if (formState.includes("/change")) return "reset";
    return "signin";
  };

  const formType = getFormType();

  const validationSchema = () => {
    switch (formType) {
      case "signup":
        return schemaSignUp;
      case "confirm":
        return schemaConfirm;
      case "forgot":
        return schemaForgot;
      case "reset":
        return schemaReset;
      default:
        return schemaSignIn;
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(validationSchema()),
  });

  const getFormTitles = () => {
    switch (formType) {
      case "signup":
        return {
          title: t("Create your RUFUS Cloud account"),
          submitButton: t("Create new account"),
          secondaryText: `${t("Already a member?")} `,
          secondaryButton: t("Sign in"),
        };
      case "confirm":
        return {
          title: t("Confirm your email address"),
          submitButton: t("Confirm"),
          secondaryText: `${t("Didn't receive a code?")} `,
          secondaryButton: t("Send again"),
        };
      case "forgot":
        return {
          title: t("Forgot password?"),
          submitButton: t("Send recovery token"),
          secondaryText: `${t("Suddenly remember?")} `,
          secondaryButton: t("Sign in"),
        };
      case "reset":
        return {
          title: t("Reset your password"),
          submitButton: t("Reset password"),
          secondaryText: `${t("Remembered your password?")} `,
          secondaryButton: t("Sign in"),
        };
      default:
        return {
          title: t("Sign in to RUFUS Cloud"),
          submitButton: t("Sign in"),
          secondaryText: `${t("Not a member?")} `,
          secondaryButton: t("Register"),
          forgotButton: t("Forgot Password?"),
        };
    }
  };

  const { title, submitButton, secondaryText, secondaryButton, forgotButton } =
    getFormTitles();

  const navigateTo = (path) => {
    clearErrors();
    navigate(path);
  };

  const onSubmit = async (data) => {
    setIsLoading(true);

    try {
      switch (formType) {
        case "signin":
          await handleSignIn(data);
          break;
        case "signup":
          await handleSignUp(data);
          break;
        case "confirm":
          await handleConfirmSignUp(data);
          break;
        case "forgot":
          await handleForgotPassword(data);
          break;
        case "reset":
          await handleResetPassword(data);
          break;
        default:
          break;
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignIn = async ({ username, password }) => {
    try {
      await handleSignOut();

      const { isSignedIn, nextStep } = await signIn({
        username,
        password,
      });

      if (isSignedIn === false && nextStep) {
        const { signInStep } = nextStep;

        if (signInStep === "CONFIRM_SIGN_UP") {
          ToastSuccess("EmailVerificationPending");

          setAuthState((prevState) => ({
            ...prevState,
            tempEmail: username,
            isEmailVerificationPending: true,
          }));
          navigate("/confirm");
        }
      } else if (isSignedIn === true && nextStep) {
        const { signInStep } = nextStep;

        if (signInStep === "DONE") {
          fetchUser();
        }
      }
    } catch (error) {
      console.log(error);
      ToastError(error);
    }
  };

  const handleSignUp = async ({ username, password }) => {
    try {
      const { isSignUpComplete, nextStep } = await signUp({
        username,
        password,
        options: {
          userAttributes: {
            email: username,
          },
        },
      });

      //Guardo en DB
      await registerUser({
        variables: {
          email: username,
          invitation_hash: invitation,
        },
      });

      if (isSignUpComplete === false && nextStep) {
        const { signUpStep } = nextStep;

        if (signUpStep === "CONFIRM_SIGN_UP") {
          ToastSuccess("EmailVerificationPending");
          setAuthState((prevState) => ({
            ...prevState,
            tempEmail: username,
            isEmailVerificationPending: true,
          }));
          navigate("/confirm");
        }
      } else {
        ToastSuccess("EmailVerificationSuccess");
        navigate("/signin");
      }
    } catch (error) {
      ToastError(error);
    }
  };

  const handleConfirmSignUp = async ({ username, code }) => {
    try {
      const { isSignUpComplete, nextStep } = await confirmSignUp({
        username,
        confirmationCode: code,
      });

      if (isSignUpComplete === true && nextStep) {
        const { signUpStep } = nextStep;

        if (signUpStep === "DONE") {
          ToastSuccess("EmailVerificationSuccess");
          setAuthState((prevState) => ({
            ...prevState,
            tempEmail: null,
            isEmailVerificationPending: false,
          }));
          navigate("/signin");
        }
      }
    } catch (error) {
      ToastError(error);
    }
  };

  const handleForgotPassword = async ({ username }) => {
    try {
      const { isPasswordReset, nextStep } = await resetPassword({ username });

      if (isPasswordReset === false && nextStep) {
        const { resetPasswordStep } = nextStep;

        if (resetPasswordStep === "CONFIRM_RESET_PASSWORD_WITH_CODE") {
          ToastSuccess("RecoveryTokenSent");
          setAuthState((prevState) => ({ ...prevState, tempEmail: username }));
          navigate("/change");
        }
      }
    } catch (error) {
      ToastError(error);
    }
  };

  const handleResetPassword = async ({ username, code, password }) => {
    try {
      await confirmResetPassword({
        username,
        confirmationCode: code,
        newPassword: password,
      });

      ToastSuccess("ResetPasswordSuccess");
      setAuthState((prevState) => ({ ...prevState, tempEmail: null }));
      navigate("/signin");
    } catch (error) {
      ToastError(error);
    }
  };

  return (
    <Form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
      <h3 className={classes.title}>{title}</h3>

      {["signin", "signup"].includes(formType) && (
        <h5 className={classes.subtitle}>
          {t("The hub for managing your timing devices and events")}
        </h5>
      )}

      {/* Email Field */}
      {["signin", "signup", "confirm", "forgot", "reset"].includes(
        formType
      ) && (
        <Form.Group className="mb-3 mt-4" controlId="formEmail">
          <Form.Label>{t("Email address")}</Form.Label>
          <Form.Control
            type="email"
            placeholder={t("Enter your email address")}
            {...register("username")}
            defaultValue={
              formType === "confirm" || formType === "reset"
                ? authState.tempEmail || ""
                : ""
            }
            disabled={formType === "confirm" || formType === "reset"}
          />
          {errors.username && (
            <Form.Text className={classes.formError}>
              {errors.username.message}
            </Form.Text>
          )}
        </Form.Group>
      )}

      {/* Password Field */}
      {["signin", "signup", "reset"].includes(formType) && (
        <Form.Group className="mb-3" controlId="formPassword">
          <Form.Label>
            {formType === "reset" ? t("New Password") : t("Password")}
          </Form.Label>
          <Form.Control
            type="password"
            placeholder={
              formType === "reset"
                ? t("Enter new password")
                : t("Enter your password")
            }
            {...register("password")}
          />
          {errors.password && (
            <Form.Text className={classes.formError}>
              {errors.password.message}
            </Form.Text>
          )}
        </Form.Group>
      )}

      {/* Code Field */}
      {["confirm", "reset"].includes(formType) && (
        <Form.Group className="mb-3" controlId="formCode">
          <Form.Label>
            {formType === "confirm" ? t("Confirmation Code") : t("Reset Code")}
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={
              formType === "confirm"
                ? t("Enter confirmation code")
                : t("Enter reset code")
            }
            {...register("code")}
          />
          {errors.code && (
            <Form.Text className={classes.formError}>
              {errors.code.message}
            </Form.Text>
          )}
        </Form.Group>
      )}

      {/* Submit Button */}
      <Button
        variant="primary"
        type="submit"
        disabled={isLoading}
        className={classes.button}
      >
        {isLoading ? (
          <>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />{" "}
            {t("Processing...")}
          </>
        ) : (
          submitButton
        )}
      </Button>
      {formType === "signup" && (
        <Form.Text className="text-white">
          <i>{t("We'll never share your email with anyone else.")}</i>
        </Form.Text>
      )}

      {/* Secondary Actions */}
      <Row className="mt-3">
        {formType === "signin" ? (
          <>
            <Col className="text-center">
              {secondaryText}
              <br />
              <Button
                variant="link"
                onClick={() => navigateTo("/signup")}
                className={classes.linkButton}
              >
                {secondaryButton}
              </Button>
            </Col>
            <Col className="text-center">
              <Button
                variant="link"
                onClick={() => navigateTo("/forgot")}
                className={classes.linkButton}
              >
                {forgotButton}
              </Button>
            </Col>
          </>
        ) : (
          <>
            <Col className="text-center">
              {formType === "signup" && (
                <Button
                  variant="link"
                  onClick={() => navigateTo("/signin")}
                  className={classes.linkButton}
                >
                  {secondaryButton}
                </Button>
              )}
              {formType === "confirm" && (
                <Button
                  variant="link"
                  onClick={async () => {
                    const username = authState.tempEmail;
                    if (username) {
                      try {
                        await resendSignUpCode({ username });
                        ToastSuccess("ConfirmationCodeSent");
                      } catch (error) {
                        ToastError(error);
                      }
                    } else {
                      ToastError("EmailNotFound");
                      navigate("/signin");
                    }
                  }}
                  className={classes.linkButton}
                >
                  {secondaryButton}
                </Button>
              )}
              {formType === "forgot" && (
                <>
                  {secondaryText}
                  <br />
                  <Button
                    variant="link"
                    onClick={() => navigateTo("/signin")}
                    className={classes.linkButton}
                  >
                    {secondaryButton}
                  </Button>
                </>
              )}
              {formType === "reset" && (
                <Button
                  variant="link"
                  onClick={() => navigateTo("/signin")}
                  className={classes.linkButton}
                >
                  {secondaryButton}
                </Button>
              )}
            </Col>
          </>
        )}
      </Row>
    </Form>
  );
};

export default AuthForm;
