import React, { createContext, useContext, useEffect } from "react";
import { useSubscription, gql } from "@apollo/client";

import { useUser } from "./UserContext";

import { ON_GENERATE_TOKEN_DEVICE } from "../graphql/subscriptions";

import { ToastError, ToastSuccess } from "../components/Helpers/ToastHelper";

export const SubscriptionContext = createContext();

export const SubscriptionProvider = ({ children }) => {
  const { userData } = useUser();
  const api_token = userData?.api_token;

  const { data, loading, error } = useSubscription(ON_GENERATE_TOKEN_DEVICE, {
    variables: { api_token },
    skip: !api_token, // Skip subscription if api_token is not available
  });

  // useEffect(() => {
  //   console.log("SubsData:", data);
  // }, [data]);

  return (
    <SubscriptionContext.Provider
      value={{
        tokenDevice: data?.onGenerateTokenDevice || null,
        loading,
        error,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

/**
 * Custom hook to consume the SubscriptionContext
 *
 * @returns {object} - The auth context value
 */
export const useSubscriptionContext = () => {
  const context = useContext(SubscriptionContext);

  if (context === undefined) {
    throw new Error(
      "useSubscriptionContext must be used within a SubscriptionProvider"
    );
  }

  return context;
};
