import { gql } from "@apollo/client";

export const ON_GENERATE_TOKEN_DEVICE = gql`
  subscription OnGenerateTokenDevice($api_token: ID!) {
    onGenerateTokenDevice(api_token: $api_token) {
      api_token
      token_device
      token_until
    }
  }
`;
