import i18n from "i18next";

// Mapping for informational toasts
const infoMessages = {
  ApiKeyMaximumReached: () => i18n.t("ApiKeyMaximumReached"),
  newDeviceBinded: () => i18n.t("newDeviceBinded"),
  deviceUnbinded: () => i18n.t("deviceUnbinded"),
  newSessionInserted: () => i18n.t("newSessionInserted"),
  cloudBoxOnline: (payload) => i18n.t("cloudBoxOnline", { serialNumber: payload.serialNumber }),
  cloudBoxOffline: (payload) => i18n.t("cloudBoxOffline", { serialNumber: payload.serialNumber }),
};

// Mapping for success toasts
const successMessages = {
  ApiKeyDeleted: () => i18n.t("ApiKeyDeleted"),
  ApiKeyCreated: () => i18n.t("ApiKeyCreated"),
  DeviceAliasUpdated: () => i18n.t("DeviceAliasUpdated"),
  NewTokenGenerated: (payload) => `${i18n.t("NewTokenGenerated")} ${payload.token}`,
  ChangeEmailSuccess: () => i18n.t("ChangeEmailSuccess"),
  CollaboratorUserTypeUpdated: () => i18n.t("CollaboratorUserTypeUpdated"),
  ProfileUpdated: () => i18n.t("ProfileUpdated"),
  CollaboratorDeleted: () => i18n.t("CollaboratorDeleted"),
  InvitationDeleted: () => i18n.t("InvitationDeleted"),
  InvitationSent: () => i18n.t("InvitationSent"),
  ChangePasswordSuccess: () => i18n.t("ChangePasswordSuccess"),
  ResetPasswordSuccess: () => i18n.t("ResetPasswordSuccess"),
  NewEmailVerificationSuccess: () => i18n.t("NewEmailVerificationSuccess"),
  EmailVerificationSuccess: () => i18n.t("EmailVerificationSuccess"),
  PhoneVerificationSuccess: () => i18n.t("PhoneVerificationSuccess"),
  PhoneRemoved: () => i18n.t("PhoneRemoved"),
  PhoneVerificationPending: () => i18n.t("PhoneVerificationPending"),
  EmailVerificationPending: () => i18n.t("EmailVerificationPending"),
  RecoveryTokenSent: () => i18n.t("RecoveryTokenSent"),
  PhoneConfirmationCodeSent: () => i18n.t("PhoneConfirmationCodeSent"),
  ConfirmationCodeSent: () => i18n.t("ConfirmationCodeSent"),
  // Add more mappings as needed
};

// Mapping for error toasts
const errorMessages = {
  DeviceTokenException: () => i18n.t("DeviceTokenException"),
  DeviceAliasException: () => i18n.t("DeviceAliasException"),
  AliasExistsException: () => i18n.t("AliasExistsException"),
  InvalidPasswordException: () => i18n.t("InvalidPasswordException"),
  UsernameExistsException: () => i18n.t("UsernameExistsException"),
  LimitExceededException: () => i18n.t("LimitExceededException"),
  CodeMismatchException: () => i18n.t("CodeMismatchException"),
  ExpiredCodeException: () => i18n.t("ExpiredCodeException"),
  NotAuthorizedException: () => i18n.t("NotAuthorizedException"),
  EmailAlreadyRegistered: () => i18n.t("EmailAlreadyRegistered"),
  WrongEmailOrPassword: () => i18n.t("WrongEmailOrPassword"),
  EmailNotFound: () => i18n.t("EmailNotFound"),
  GetUserByCredentialsException: () => i18n.t("GetUserByCredentialsException"), //TODO: Add
  // Add more mappings as needed
};

export { infoMessages, successMessages, errorMessages };
