import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";

import { GET_USER_API_KEYS } from "../../graphql/queries";

import Table from "react-bootstrap/Table";

import { useUser } from "../../context/UserContext";

import classes from "./ApiKeysTable.module.css";

import ApiKeyRow from "./ApiKeyRow";

const ApiKeysTable = () => {
  const { t } = useTranslation();
  const { userData } = useUser();
  const company_api_token = userData?.company_api_token || null;
  const { data: apiKeysData } = useQuery(GET_USER_API_KEYS, {
    variables: { company_api_token },
    skip: !company_api_token,
    fetchPolicy: "cache-and-network",
  });
  const apiKeysList = apiKeysData?.getUserApiKeys || [];

  return (
    <div className={classes["table-container"]}>
      {apiKeysList.length > 0 && (
        <>
          <h5>{t("Active api keys")}</h5>{" "}
          <h6>{t("active-api-keys-subtitle")}</h6>
          <Table className={classes.table} striped hover responsive>
            <thead>
              <tr>
                <th>{t("Alias")}</th>
                <th>{t("Api key")}</th>
                <th>{t("Access type")}</th>
                <th>{t("Allow unbind")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {apiKeysList.map((apiKey) => (
                <ApiKeyRow key={apiKey.api_key} apiKey={apiKey} />
              ))}
            </tbody>
          </Table>
        </>
      )}
    </div>
  );
};

export default ApiKeysTable;
