// src/context/AuthContext.js

import React, { createContext, useState, useEffect, useContext } from "react";
import {
  getCurrentUser,
  signOut,
  updateUserAttribute,
  sendUserAttributeVerificationCode,
  confirmUserAttribute,
  fetchUserAttributes,
  deleteUserAttributes,
  updatePassword,
} from "aws-amplify/auth";

import { ToastError, ToastSuccess } from "../components/Helpers/ToastHelper";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    user: null,
    loading: true,
    tempEmail: null, // For temporary storage during confirmation/reset
    isEmailVerificationPending: null,
    isPhoneVerificationPending: null,
  });

  const fetchUser = async () => {
    try {
      const cognitoUser = await getCurrentUser();
      const { signInDetails } = cognitoUser;
      const user = {
        email: signInDetails.loginId,
      };

      // console.log("cognitoUser:", cognitoUser);

      // Access user attributes
      const attributes = await fetchUserAttributes();

      // console.log("attributes:", attributes);

      setAuthState({
        isAuthenticated: true,
        user,
        loading: false,
        tempEmail: null,
        tempPhone: null,
        isEmailVerificationPending: !parseBoolean(attributes?.email_verified),
        isPhoneVerificationPending: !parseBoolean(
          attributes?.phone_number_verified
        ),
      });
    } catch (error) {
      setAuthState({
        isAuthenticated: false,
        user: null,
        loading: false,
        tempEmail: null,
        tempPhone: null,
        isEmailVerificationPending: null,
        isPhoneVerificationPending: null,
      });
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      setAuthState({
        isAuthenticated: false,
        user: null,
        loading: false,
        tempEmail: null,
        isEmailVerificationPending: null,
        isPhoneVerificationPending: null,
      });
    } catch (error) {
      console.error("Error signing out:", error);
      ToastError(error);
    }
  };

  const handleUpdateUserAttribute = async (
    attributeKey,
    value,
    api_token,
    updateUser
  ) => {
    try {
      const output = await updateUserAttribute({
        userAttribute: {
          attributeKey,
          value,
        },
      });
      const user = {};

      if (attributeKey === "email") {
        user["api_token"] = api_token;
        user["email"] = value;

        await updateUser({
          variables: {
            user,
          },
        });

        delete user["api_token"];

        setTimeout(() => {
          setAuthState((prevState) => ({
            ...prevState,
            tempEmail: value,
            user,
            isEmailVerificationPending: true,
          }));
        }, 1000);
      }

      if (attributeKey === "phone_number") {
        user["api_token"] = api_token;
        user["telephone"] = value;

        await updateUser({
          variables: {
            user,
          },
          refetchQueries: ["GetUserByCredentials"],
        });

        setTimeout(() => {
          setAuthState((prevState) => ({
            ...prevState,
            tempPhone: value,
            isPhoneVerificationPending: true,
          }));
        }, 1000);
      }

      handleUpdateUserAttributeNextSteps(attributeKey, output);
    } catch (error) {
      console.error("Error updating user attributes:", error);
      ToastError(error);
    }
  };

  const handleUpdateUserAttributeNextSteps = async (attributeKey, output) => {
    const { nextStep } = output;

    switch (nextStep.updateAttributeStep) {
      case "CONFIRM_ATTRIBUTE_WITH_CODE":
        ToastSuccess("ConfirmationCodeSent");
        break;

      case "DONE":
        if (attributeKey === "email") {
          ToastSuccess("NewEmailVerificationSuccess");
          setAuthState((prevState) => ({
            ...prevState,
            tempEmail: null,
            isEmailVerificationPending: false,
          }));
        }

        if (attributeKey === "phone_number") {
          ToastSuccess("PhoneVerificationSuccess");
          setAuthState((prevState) => ({
            ...prevState,
            isPhoneVerificationPending: false,
          }));
        }

        break;
      default:
        break;
    }
  };

  const handleSendUserAttributeVerificationCode = async (userAttributeKey) => {
    try {
      await sendUserAttributeVerificationCode({
        userAttributeKey,
      });
      ToastSuccess("ConfirmationCodeSent");
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmUserAttribute = async (
    userAttributeKey,
    confirmationCode
  ) => {
    try {
      await confirmUserAttribute({ userAttributeKey, confirmationCode });

      if (userAttributeKey === "email") {
        ToastSuccess("NewEmailVerificationSuccess");

        setAuthState((prevState) => ({
          ...prevState,
          tempEmail: null,
          isEmailVerificationPending: false,
        }));
      }

      if (userAttributeKey === "phone_number") {
        ToastSuccess("NewPhoneVerificationSuccess");

        setAuthState((prevState) => ({
          ...prevState,
          tempPhone: null,
          isPhoneVerificationPending: false,
        }));
      }
    } catch (error) {
      console.log(error);
      ToastError(error);
    }
  };

  const handleDeleteUserAttribute = async (
    attributeKey,
    api_token,
    updateUser
  ) => {
    try {
      await deleteUserAttributes({
        userAttributeKeys: [attributeKey],
      });

      if (attributeKey === "phone_number") {
        const user = {};
        user["telephone"] = null;
        user["country_code"] = null;
        user["api_token"] = api_token;

        await updateUser({
          variables: {
            user,
          },
          refetchQueries: ["GetUserByCredentials"],
        });

        setTimeout(() => {
          setAuthState((prevState) => ({
            ...prevState,
            tempPhone: null,
            isPhoneVerificationPending: false,
          }));
        }, 1000);

        ToastSuccess("PhoneRemoved");
      }
    } catch (error) {
      console.error(error);
      ToastError(error);
    }
  };

  const handleUpdatePassword = async (oldPassword, newPassword) => {
    try {
      await updatePassword({ oldPassword, newPassword });

      ToastSuccess("ChangePasswordSuccess");
    } catch (error) {
      console.error(error);
      ToastError(error);
    }
  };

  // Check current authenticated user on mount
  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authState,
        setAuthState,
        handleSignOut,
        fetchUser,
        handleUpdateUserAttribute,
        handleSendUserAttributeVerificationCode,
        handleConfirmUserAttribute,
        handleDeleteUserAttribute,
        handleUpdatePassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

/**
 * Custom hook to consume the AuthContext
 *
 * @returns {object} - The auth context value
 */
export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }

  return context;
};

// Helper function to parse "true"/"false" and true/false to a boolean
const parseBoolean = (value) => {
  if (typeof value === "string") {
    return value.toLowerCase() === "true";
  }
  return Boolean(value);
};
