// src/components/PassingsTable.js

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Table, Pagination, Button } from "react-bootstrap";
import PassingRow from "./PassingRow";
import PassingCard from "./PassingCard";

const generatePaginationItems = (totalPages, currentPage, handlePageChange) => {
  const items = [];

  if (totalPages <= 5) {
    // If total pages are 5 or less, show all page numbers
    for (let number = 1; number <= totalPages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }
  } else {
    // Always show the first page
    items.push(
      <Pagination.Item
        key={1}
        active={1 === currentPage}
        onClick={() => handlePageChange(1)}
      >
        1
      </Pagination.Item>
    );

    // Determine when to show left ellipsis
    if (currentPage > 3) {
      items.push(<Pagination.Ellipsis key="start-ellipsis" disabled />);
    }

    // Calculate the range of page numbers to show
    const startPage = Math.max(2, currentPage - 1);
    const endPage = Math.min(totalPages - 1, currentPage + 1);

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    // Determine when to show right ellipsis
    if (currentPage < totalPages - 2) {
      items.push(<Pagination.Ellipsis key="end-ellipsis" disabled />);
    }

    // Always show the last page
    items.push(
      <Pagination.Item
        key={totalPages}
        active={totalPages === currentPage}
        onClick={() => handlePageChange(totalPages)}
      >
        {totalPages}
      </Pagination.Item>
    );
  }

  return items;
};

const PassingsTable = ({ passings }) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const totalPages = Math.ceil(passings.length / itemsPerPage);
  const indexOfLastPassing = currentPage * itemsPerPage;
  const indexOfFirstPassing = indexOfLastPassing - itemsPerPage;
  const currentPassings = passings.slice(
    indexOfFirstPassing,
    indexOfLastPassing
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Determine if the view is mobile based on window width
  const isMobile = window.innerWidth < 576;

  return (
    <>
      {!isMobile ? (
        <Table striped hover responsive className="mb-3">
          <thead>
            <tr>
              <th>#</th>
              <th className="d-none d-sm-table-cell">{t("Bib")}</th>
              <th>{t("Chip")}</th>
              <th className="d-none d-md-table-cell">{t("ID")}</th>
              <th>{t("Timestamp")}</th>
              <th>{t("Localization")}</th>
              <th>{t("Status")}</th>
            </tr>
          </thead>
          <tbody>
            {currentPassings.map((passing, index) => (
              <PassingRow
                key={passing._id}
                passing={passing}
                index={indexOfFirstPassing + index + 1}
              />
            ))}
          </tbody>
        </Table>
      ) : (
        <div>
          {currentPassings.map((passing) => (
            <PassingCard key={passing._id} passing={passing} />
          ))}
        </div>
      )}
      {totalPages > 1 && (
        <Pagination className="justify-content-center">
          <Pagination.First
            onClick={() => handlePageChange(1)}
            disabled={currentPage === 1}
          />
          <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {generatePaginationItems(totalPages, currentPage, handlePageChange)}
          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
          <Pagination.Last
            onClick={() => handlePageChange(totalPages)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      )}
    </>
  );
};

export default PassingsTable;
