import { InMemoryCache, makeVar } from '@apollo/client';

import { authInitialValue } from './models/Auth';
import { uiInitialValue } from './models/Ui';
// import { Event, eventInitialValue } from './models/Event';

const authVar = makeVar(authInitialValue);
const uiVar = makeVar(uiInitialValue);
// const eventVar = makeVar<Event>(eventInitialValue);

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        credentials: {
          read() {
            return authVar();
          },
        },
        ui: {
          read() {
            return uiVar();
          },
        },
        // event: {
        //   read() {
        //     return eventVar();
        //   },
        // },
      },
    },
  },
});

export { cache, authVar, uiVar };
