import classes from "./NoSessions.module.css";

import Image from "react-bootstrap/Image";
import imgNoSessions from "../../assets/no-info-grey.png";


const NoSessions = () => {

  return (
    <div className={classes.container}>
      <div className={classes["no-sessions"]}>
        <Image src={imgNoSessions} />
      </div>
    </div>
  );
};

export default NoSessions;
