import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Nav, Navbar, Badge } from "react-bootstrap";
import { Icon } from "../UI/Icon";
import { useUser } from "../../context/UserContext";
import rufusLogo from "../../assets/logo-rufus-navbar.svg";
import "./NavBar.css";

const NavBar = ({
  showNotificationIcon = false,
  onNotificationIconClick = () => {},
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userData } = useUser();

  const devicesClickHandler = () => {
    navigate("/devices");
  };

  const eventsClickHandler = () => {
    window.open("https://rm.runonrufus.com/", "_blank");
    return null;
  };

  const profileClickHandler = () => {
    navigate("/profile");
  };

  const apiDocsClickHandler = () => {
    window.open("https://help.runonrufus.com", "_blank");
    return null;
  };

  return (
    <Navbar bg="dark" expand="lg" variant="dark">
      <Container>
        <Navbar.Brand onClick={devicesClickHandler}>
          <img
            src={rufusLogo}
            width="30"
            height="30"
            className="d-inline-block align-top cursor-pointer"
            alt="RUFUS beta logo"
          />{" "}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          {/* Left-aligned navigation links */}
          <Nav className="me-auto">
            <Nav.Link onClick={devicesClickHandler}>{t("Devices")}</Nav.Link>
            <Nav.Link
              onClick={eventsClickHandler}
              className="d-flex align-items-center"
            >
              {t("Race Manager Web")}{" "}
              <Icon iconName={"BoxArrowUpRight"} size={15} className="ms-1" />
            </Nav.Link>
          </Nav>
          {/* Right-aligned navigation links */}
          <Nav className="ms-auto">
            <Nav.Link
              onClick={apiDocsClickHandler}
              className="d-flex align-items-center"
            >
              <Icon iconName={"QuestionCircle"} size={16} className="me-1" />{" "}
              {t("Get Help")}
            </Nav.Link>
            <Nav.Link
              onClick={profileClickHandler}
              className="d-flex align-items-center"
            >
              <Icon
                iconName={userData?.company ? "BuildingFill" : "PersonFill"}
                size={16}
                className="me-1"
              />{" "}
              {userData?.company
                ? userData?.company
                : userData?.name
                ? userData?.name
                : userData?.email}
            </Nav.Link>
            {showNotificationIcon && (
              <Nav.Link
                onClick={() => {
                  if (showNotificationIcon) onNotificationIconClick(true);
                }}
                className="d-flex align-items-center position-relative"
              >
                <Icon iconName={"Bell"} size={16} className="me-1" />{" "}
                {/* {t("Notifications")} */}
                <Badge
                  pill
                  bg="danger"
                  className="position-absolute translate-middle"
                >
                  1
                </Badge>
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
