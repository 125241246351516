export const awsExports = {
//   aws_appsync_graphqlEndpoint: process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT, //RUFUS Cloud GraphQL Api Api Key
//   aws_appsync_region: process.env.REACT_APP_APPSYNC_GRAPHQL_REGION,
//   aws_appsync_authenticationType: process.env.REACT_APP_APPSYNC_AUTH_TYPE,
//   aws_appsync_apiKey: process.env.REACT_APP_APPSYNC_API_KEY, //RUFUS Cloud GraphQL Api Api Key

  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      userPoolClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    },
  },
};

export default awsExports;
