import { gql } from "@apollo/client";

export const getPublishedEventsList = /* GraphQL */ `
  query GetPublishedEventsList($company_api_token: ID!) {
    getPublishedEventsList(company_api_token: $company_api_token) {
      event_token
      active
      name
      display_name
      description
      participants
      races
      start_date
      last_update
      background_image
      background_thumbnail_small
      background_thumbnail_medium
      event_slug
      notifications_sms
      notifications_email
      notifications_push
    }
  }
`;
export const getEventPublicationDetails = /* GraphQL */ `
  query GetEventPublicationDetails($event_token: ID!) {
    getEventPublicationDetails(event_token: $event_token) {
      event_token
      active
      name
      display_name
      description
      participants
      races
      start_date
      last_update
      background_image
      background_thumbnail_small
      background_thumbnail_medium
      event_slug
      notifications_sms
      notifications_email
      notifications_push
    }
  }
`;
export const validateSmsToken = /* GraphQL */ `
  query ValidateSmsToken($id: ID!, $sms_token: ID!) {
    validateSmsToken(id: $id, sms_token: $sms_token)
  }
`;
export const getSessionId = /* GraphQL */ `
  query GetSessionId($company_api_token: ID!, $session_id: ID!) {
    getSessionId(company_api_token: $company_api_token, session_id: $session_id)
  }
`;
export const getDeviceId = /* GraphQL */ `
  query GetDeviceId($company_api_token: ID!, $device_id: ID!) {
    getDeviceId(company_api_token: $company_api_token, device_id: $device_id)
  }
`;
export const getSessionPassingsById = /* GraphQL */ `
  query GetSessionPassingsById($session_id: ID!) {
    getSessionPassingsById(session_id: $session_id) {
      _id
      token_session
      timestamp
      num_passing
      latitude
      longitude
      status
      participant_id
      gender
      bib
      chip
      event_token
    }
  }
`;

// REVISED
export const GET_SESSION_PASSINGS = gql`
  query GetSessionPassings($token_session: ID!) {
    getSessionPassings(token_session: $token_session) {
      _id
      token_session
      timestamp
      num_passing
      latitude
      longitude
      status
      participant_id
      gender
      bib
      chip
      event_token
    }
  }
`;
export const GET_DEVICE_SESSIONS = gql`
  query GetDeviceSessions($company_api_token: ID!, $device_id: ID!) {
    getDeviceSessions(
      company_api_token: $company_api_token
      device_id: $device_id
    ) {
      _id
      token_session
      deviceid
      creation_date
      duration
      last_refresh
      active
      passings
      alias
      user_api_token
      numOfPassings
    }
  }
`;
export const GET_DEVICES_BY_COMPANY = gql`
  query GetDevicesByCompany($company_api_token: ID!) {
    getDevicesByCompany(company_api_token: $company_api_token) {
      id
      deviceid
      company_api_token
      type
      model
      alias
      firmware
      serial_number
      device_signature
      last_login
      creation_date
      event_token
      original
      numOfSessions
    }
  }
`;
export const GET_DEVICES_BY_COMPANY_WITH_STATUS = gql`
  query GetDevicesByCompanyWithStatus($company_api_token: ID!) {
    getDevicesByCompanyWithStatus(company_api_token: $company_api_token) {
      id
      deviceid
      company_api_token
      type
      model
      alias
      firmware
      serial_number
      device_signature
      last_login
      creation_date
      event_token
      event_name
      last_passing_timestamp
      last_passing_location_timestamp
      last_passing_known_latitude
      last_passing_known_longitude
      original
      numOfSessions
      isCloudBox
      status {
        batteryPercentage
        batteryVolts
        connected
        cpuTemperature
        disconnectReason
        hasPower
        releaseDate
        startMode
        timestamp
        readerStatus
        status4G
        status4GMessage
        location {
          statusGps
          statusGpsMessage
          googleMapsLink
          lastSync
          latitudeDecimal
          longitudeDecimal
        }
      }
    }
  }
`;
export const GET_USER_BY_CREDENTIALS = gql`
  query GetUserByCredentials($email: AWSEmail!, $api_token: ID) {
    getUserByCredentials(email: $email, api_token: $api_token) {
      id
      cognito_id
      api_token
      company_api_token
      name
      lastname
      fullname
      user_type
      is_owner
      company
      email
      telephone
      country_code
      onboarding_completed
      email_notifications
      sms_notifications
      preferred_language
      country
      city
      last_login
      token_device
      token_until
      image_url
    }
  }
`;
export const GET_USER_INVITATIONS = gql`
  query GetUserInvitations($inviter_api_token: ID!) {
    getUserInvitations(inviter_api_token: $inviter_api_token) {
      id
      invitation_email
      company_api_token
      inviter_api_token
      hash
      user_type
      consumed
    }
  }
`;
export const GET_COLLABORATORS = gql`
  query GetCollaborators($company_api_token: ID!) {
    getCollaborators(company_api_token: $company_api_token) {
      id
      collaborator_email
      company_api_token
      collaborator_api_token
      user_type
    }
  }
`;
export const GET_USER_API_KEYS = gql`
  query GetUserApiKeys($company_api_token: ID!) {
    getUserApiKeys(company_api_token: $company_api_token) {
      api_key
      alias
      access_type
      allow_unbind
    }
  }
`;
export const GET_USER_DISMISSED_NOTIFICATIONS = gql`
  query GetUserDismissedNotifications($user_api_token: ID!) {
    getUserDismissedNotifications(user_api_token: $user_api_token)
  }
`;
