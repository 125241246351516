import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

import DeviceCard from "./DeviceCard";
import GenerateTokenCard from "./GenerateTokenCard";

const DevicesList = (props) => {
  const devices = props.devices;

  return (
    <Container>
      <Row xs={1} md={3} className="g-4 mb-4">
        {devices.map((device) => (
          <DeviceCard key={device.id} device={device} />
        ))}
        <GenerateTokenCard/>
      </Row>

    </Container>
  );
};

export default DevicesList;
