// src/components/PrivateRoute.js

import React from "react";
import { Navigate } from "react-router-dom";

import { useAuth } from "../../context/AuthContext";

import Loading from "../Loading/Loading";

const PrivateRoute = ({ children }) => {
  const { authState } = useAuth();

  if (authState.loading) {
    return <Loading />;
  }

  return authState.isAuthenticated ? children : null;
};

export default PrivateRoute;
