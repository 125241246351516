import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { formatDistanceToNow } from "date-fns";

import classes from "./SessionItem.module.css";

import Badge from "react-bootstrap/Badge";
import ListGroup from "react-bootstrap/ListGroup";
import { Calendar } from "react-bootstrap-icons";

const SessionItem = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const { deviceId } = params;
  const session = props.session;
  const { alias, creation_date, active, numOfPassings, _id } = session;

  const clickHandler = (id) => {
    navigate("/devices/" + deviceId + "/" + id);
  };

  return (
    <ListGroup.Item
      className={`d-flex justify-content-between align-items-start ${classes.listItem}`}
      onClick={() => clickHandler(_id)}
    >
      <div className="ms-2 me-auto">
        <div className="fw-bold">
          {alias} {active && <Badge bg="danger">ACTIVE</Badge>}
        </div>
        <Calendar /> {t("Created ")}
        {formatDistanceToNow(new Date(creation_date), {
          addSuffix: true,
        })}
      </div>
      <Badge bg="primary" pill>
        {numOfPassings}
      </Badge>
    </ListGroup.Item>
  );
};

export default SessionItem;
