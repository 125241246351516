import React from "react";
import { useTranslation } from "react-i18next";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import { formatDistanceToNow } from "date-fns";

const DeviceStatusDetails = ({ status }) => {
  const { t } = useTranslation();

  if (!status) {
    return <p>{t("Status information is not available.")}</p>;
  }

  const {
    connected,
    disconnectReason,
    releaseDate,
    timestamp,
    batteryVolts,
    batteryPercentage,
    cpuTemperature,
    hasPower,
    startMode,
    readerStatus,
    status4G,
    status4GMessage,
    location,
  } = status;

  // Common style for the cards
  const cardStyle = {
    maxWidth: "600px",
    margin: "0",
  };

  // Common style for the tables
  const tableStyle = {
    tableLayout: "fixed",
    width: "100%",
  };

  // Style for the first column
  const firstColumnStyle = {
    width: "40%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  // Style for the second column
  const secondColumnStyle = {
    width: "60%",
  };

  return (
    <div>
      {/* Connection Status */}
      <Card className="mb-3" style={cardStyle}>
        <Card.Header>
          <h5>{t("IoT Connection Status")}</h5>
        </Card.Header>
        <Card.Body>
          <Table responsive bordered style={tableStyle}>
            <tbody>
              <tr>
                <td style={firstColumnStyle}>
                  <strong>{t("Connected")}:</strong>
                </td>
                <td style={secondColumnStyle}>
                  {connected ? (
                    <Badge bg="success">{t("Yes")}</Badge>
                  ) : (
                    <Badge bg="danger">{t("No")}</Badge>
                  )}
                </td>
              </tr>
              {disconnectReason && (
                <tr>
                  <td style={firstColumnStyle}>
                    <strong>{t("Disconnect Reason")}:</strong>
                  </td>
                  <td style={secondColumnStyle}>{disconnectReason}</td>
                </tr>
              )}
              {timestamp && (
                <tr>
                  <td style={firstColumnStyle}>
                    <strong>{t("Last Seen")}:</strong>
                  </td>
                  <td style={secondColumnStyle}>
                    {connected
                      ? t("Now")
                      : formatDistanceToNow(new Date(timestamp), {
                          addSuffix: true,
                        })}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      {/* Power Status */}
      <Card className="mb-3" style={cardStyle}>
        <Card.Header>
          <h5>{t("Power Status")}</h5>
        </Card.Header>
        <Card.Body>
          <Table responsive bordered style={tableStyle}>
            <tbody>
              {typeof hasPower === "boolean" && (
                <tr>
                  <td style={firstColumnStyle}>
                    <strong>{t("Has Power")}:</strong>
                  </td>
                  <td style={secondColumnStyle}>
                    {hasPower ? (
                      <Badge bg="success">{t("Yes")}</Badge>
                    ) : (
                      <Badge bg="danger">{t("No")}</Badge>
                    )}
                  </td>
                </tr>
              )}
              {batteryVolts && (
                <tr>
                  <td style={firstColumnStyle}>
                    <strong>{t("Battery Voltage")}:</strong>
                  </td>
                  <td style={secondColumnStyle}>{batteryVolts} V</td>
                </tr>
              )}
              {batteryPercentage && (
                <tr>
                  <td style={firstColumnStyle}>
                    <strong>{t("Battery Percentage")}:</strong>
                  </td>
                  <td style={secondColumnStyle}>{batteryPercentage} %</td>
                </tr>
              )}
              {cpuTemperature && (
                <tr>
                  <td style={firstColumnStyle}>
                    <strong>{t("CPU Temperature")}:</strong>
                  </td>
                  <td style={secondColumnStyle}>{cpuTemperature} °C</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      {/* Operation Status */}
      <Card className="mb-3" style={cardStyle}>
        <Card.Header>
          <h5>{t("Operation Status")}</h5>
        </Card.Header>
        <Card.Body>
          <Table responsive bordered style={tableStyle}>
            <tbody>
              {typeof startMode === "boolean" && (
                <tr>
                  <td style={firstColumnStyle}>
                    <strong>{t("Start Mode")}:</strong>
                  </td>
                  <td style={secondColumnStyle}>
                    {startMode ? (
                      <Badge bg="success">{t("Yes")}</Badge>
                    ) : (
                      <Badge bg="secondary">{t("No")}</Badge>
                    )}
                  </td>
                </tr>
              )}
              {readerStatus && (
                <tr>
                  <td style={firstColumnStyle}>
                    <strong>{t("Reader Status")}:</strong>
                  </td>
                  <td style={secondColumnStyle}>{readerStatus}</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      {/* 4G Status */}
      <Card className="mb-3" style={cardStyle}>
        <Card.Header>
          <h5>{t("4G Status")}</h5>
        </Card.Header>
        <Card.Body>
          <Table responsive bordered style={tableStyle}>
            <tbody>
              {status4G && (
                <tr>
                  <td style={firstColumnStyle}>
                    <strong>{t("Status 4G")}:</strong>
                  </td>
                  <td style={secondColumnStyle}>{status4G}</td>
                </tr>
              )}
              {status4GMessage && (
                <tr>
                  <td style={firstColumnStyle}>
                    <strong>{t("Status 4G Message")}:</strong>
                  </td>
                  <td style={secondColumnStyle}>{status4GMessage}</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      {/* GPS Status */}
      <Card className="mb-3" style={cardStyle}>
        <Card.Header>
          <h5>{t("GPS Status")}</h5>
        </Card.Header>
        <Card.Body>
          <Table responsive bordered style={tableStyle}>
            <tbody>
              {location?.statusGps && (
                <tr>
                  <td style={firstColumnStyle}>
                    <strong>{t("GPS Status")}:</strong>
                  </td>
                  <td style={secondColumnStyle}>{location.statusGps}</td>
                </tr>
              )}
              {location?.statusGpsMessage && (
                <tr>
                  <td style={firstColumnStyle}>
                    <strong>{t("GPS Status Message")}:</strong>
                  </td>
                  <td style={secondColumnStyle}>{location.statusGpsMessage}</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};

export default DeviceStatusDetails;
