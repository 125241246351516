import { useTranslation } from "react-i18next";

import classes from "./NoPassings.module.css";

import Image from 'react-bootstrap/Image'
import imgNoPassings from "../../assets/no-info-grey.png";

const NoPassings = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes["no-passings"]}>
        <Image rounded src={imgNoPassings} />
      </div>
    </div>
  );
};

export default NoPassings;
