import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useMutation, useQuery } from "@apollo/client";

import { GET_COLLABORATORS } from "../../graphql/queries";
import { REMOVE_COLLABORATOR, UPDATE_USER_TYPE } from "../../graphql/mutations";

import { useUser } from "../../context/UserContext";

import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";
import { PersonCheckFill, Trash3Fill } from "react-bootstrap-icons";

import { USERTYPES } from "../../store/models/user-types";

import classes from "./CollaboratorsList.module.css";

import { ToastError, ToastSuccess } from "../Helpers/ToastHelper";

const CollaboratorsList = () => {
  const { t } = useTranslation();
  const { userData } = useUser();
  const api_token = userData?.api_token || null;
  const company_api_token = userData?.company_api_token || null;
  const userType = userData?.user_type || "READ_ONLY";
  const { data: collaboratorsData, loading: isLoading } = useQuery(
    GET_COLLABORATORS,
    {
      variables: { company_api_token },
      skip: !company_api_token,
      fetchPolicy: "cache-and-network",
    }
  );
  const [removeCollaborator] = useMutation(REMOVE_COLLABORATOR, {
    refetchQueries: ["GetCollaborators"],
    onCompleted(data) {
      if (data.removeCollaborator === "InvitationRemovedOk") {
        ToastSuccess("CollaboratorDeleted");
      }
    },
    onError(error) {
      console.error(error);
      ToastError(error);
    },
  });
  const [updateUserType] = useMutation(UPDATE_USER_TYPE, {
    refetchQueries: ["GetCollaborators"],
    onCompleted(data) {
      if (data.updateUserType === "UserTypeUpdateOk") {
        ToastSuccess("CollaboratorUserTypeUpdated");
      }
    },
    onError(error) {
      console.error(error);
      ToastError(error);
    },
  });
  const collaboratorsList = collaboratorsData?.getCollaborators || [];
  const [hoover, setHoover] = useState(null);

  const changeTypeHandler = (id, user_type) => {
    updateUserType({
      variables: {
        id,
        user_type,
      },
    });
  };

  const onMouseEnterHandler = (button) => {
    setHoover(button);
  };

  const onMouseLeaveHandler = (button) => {
    setHoover(null);
  };

  return (
    <div className={classes["list-container"]}>
      {collaboratorsList.length > 1 && <h5>{t("Collaborators")}</h5>}
      <ListGroup>
        {collaboratorsList.map(
          (collaborator) =>
            collaborator.collaborator_api_token !== api_token && (
              <ListGroup.Item
                key={collaborator.id}
                onMouseEnter={() => onMouseEnterHandler(collaborator.id)}
                onMouseLeave={() => onMouseLeaveHandler(collaborator.id)}
              >
                <PersonCheckFill /> {collaborator.collaborator_email}{" "}
                {userType !== USERTYPES.ADMIN &&
                  userType !== USERTYPES.OWNER && (
                    <Badge bg="info">{collaborator.user_type}</Badge>
                  )}{" "}
                {(userType === USERTYPES.ADMIN ||
                  userType === USERTYPES.OWNER) &&
                  collaborator.user_type !== USERTYPES.OWNER && (
                    <>
                      <DropdownButton
                        id="dropdown-basic-button"
                        title={collaborator.user_type}
                        as="span"
                        size="sm"
                      >
                        <Dropdown.Item
                          as="button"
                          onClick={() =>
                            changeTypeHandler(
                              collaborator.id,
                              USERTYPES.READ_ONLY
                            )
                          }
                        >
                          Read only
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="button"
                          onClick={() =>
                            changeTypeHandler(collaborator.id, USERTYPES.TIMER)
                          }
                        >
                          Timer
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="button"
                          onClick={() =>
                            changeTypeHandler(collaborator.id, USERTYPES.ADMIN)
                          }
                        >
                          Admin
                        </Dropdown.Item>
                      </DropdownButton>{" "}
                      <Trash3Fill
                        onClick={() =>
                          removeCollaborator({
                            variables: { id: collaborator.id },
                          })
                        }
                        disabled={isLoading[collaborator.id]}
                        display={hoover === collaborator.id ? "inline" : "none"}
                        className={classes["trash"]}
                      />
                      {/* <Button
                        variant="link"
                        onClick={() => removeHandler(collaborator.id)}
                        disabled={isDeletingCollaborator[collaborator.id]}
                      >
                        {isDeletingCollaborator[collaborator.id]
                          ? "removing..."
                          : "remove"}
                      </Button> */}
                    </>
                  )}
              </ListGroup.Item>
            )
        )}
      </ListGroup>
    </div>
  );
};

export default CollaboratorsList;
