// DeviceLocation.js
import React from "react";
import { useTranslation } from "react-i18next";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { formatDistanceToNow } from "date-fns";

import Image from "react-bootstrap/Image";

import classes from "./DeviceLocation.module.css";

import imgNoLocation from "../../assets/no-position-grey.png";

const DeviceLocation = ({ location }) => {
  const { t } = useTranslation();

  // Load the Google Maps script
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyCx4FXMpKr5ZACLzSX0Fxtl5nYL5I0NZIw", // Replace with your API key
  });

  if (!location) {
    return (
      <>
        <p>{t("Location information is not available.")}</p>
        <div className={classes.container}>
          <div className={classes["no-location"]}>
            <Image rounded src={imgNoLocation} />
          </div>
        </div>
      </>
    );
  }

  const { latitudeDecimal, longitudeDecimal, lastSync, locationFromPassing } =
    location;

  // Convert latitude and longitude to numbers
  const latitude = parseFloat(latitudeDecimal);
  const longitude = parseFloat(longitudeDecimal);

  if (isNaN(latitude) || isNaN(longitude)) {
    return (
      <>
        <p>{t("The device has not know GPS position")}.</p>
        <div className={classes.container}>
          <div className={classes["no-location"]}>
            <Image rounded src={imgNoLocation} />
          </div>
        </div>
      </>
    );
  }

  if (!isLoaded) {
    return <p>{t("Loading map...")}</p>;
  }

  const mapContainerStyle = {
    width: "100%",
    height: "400px",
  };

  const center = {
    lat: latitude,
    lng: longitude,
  };

  return (
    <div>
      {lastSync && !locationFromPassing && (
        <p>
          {t("Last GPS synchronization")}{" "}
          {formatDistanceToNow(new Date(lastSync), { addSuffix: true })}
        </p>
      )}
      {lastSync && locationFromPassing && (
        <p>
          {t("Last position from passings received")}{" "}
          {formatDistanceToNow(new Date(lastSync), { addSuffix: true })}
        </p>
      )}
      {!lastSync && (
        <p>{t("The device has not know GPS position timestamp")}</p>
      )}
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={13}
      >
        <Marker position={center} />
      </GoogleMap>
      {latitude && longitude && (
        <p>
          {t("Lat: ") + `${latitude}`} <br />
          {t("Long: ") + `${longitude}`}
        </p>
      )}
    </div>
  );
};

export default DeviceLocation;
