// src/context/UserContext.js

import React, { createContext, useContext } from "react";
import { useQuery } from "@apollo/client";

import {
  GET_USER_BY_CREDENTIALS,
  GET_USER_DISMISSED_NOTIFICATIONS,
} from "../graphql/queries";

import { AuthContext } from "./AuthContext";
import { ToastError } from "../components/Helpers/ToastHelper"; // Adjust the import path as needed

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { authState, handleSignOut } = useContext(AuthContext);
  const { isAuthenticated, user, loading: authLoading } = authState;
  const email = isAuthenticated && user?.email ? user.email : null;
  const {
    data,
    loading: userLoading,
    error,
  } = useQuery(GET_USER_BY_CREDENTIALS, {
    variables: { email },
    skip: !isAuthenticated || !email,
    fetchPolicy: "network-only",
  });
  const userData = data?.getUserByCredentials || null;
  const api_token = userData?.api_token || null;
  const { data: userNotificationsData } = useQuery(
    GET_USER_DISMISSED_NOTIFICATIONS,
    {
      variables: { user_api_token: api_token },
      skip: !isAuthenticated || !api_token, 
      fetchPolicy: "network-only",
    }
  );
  const userNotifications =
    userNotificationsData?.getUserDismissedNotifications || null;

  if (error) {
    ToastError("GetUserByCredentialsException");
    handleSignOut();
  }

  return (
    <UserContext.Provider
      value={{
        userData,
        userNotifications,
        loading: authLoading || userLoading,
        error,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

/**
 * Custom hook to consume the UserContext
 *
 * @returns {object} - The user context value
 */
export const useUser = () => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }

  return context;
};
